@import "variables";

// Set body and html
html,
body {
    min-height: 100%;
}
body {
    margin: 0;
}

// Set headings
@function headings($from: 1, $to: 6) {
    @if $from == $to {
        @return "h#{$from}";
    } @else {
        @return "h#{$from}," + headings($from + 1, $to);
    }
}
#{headings(1,6)} {
    color: $color-black-100;
}

// Set common values
a {
    color: $color-black-100;
    cursor: pointer;
    outline: none;
    text-decoration: underline;
    &:hover {
        color: $color-black-100;
    }
}
.primary-ancher {
    color: $color-primary;
}
.accent-ancher {
    color: $color-accent;
}

// Hide section
.hide {
    display: none;
}

// Text alignments
.text-left {
    text-align: left !important;
}
.text-center {
    text-align: center !important;
}
.text-right {
    text-align: right !important;
}

// Content alignment
.align-center {
    justify-content: center;
}
.align-left {
    justify-content: flex-start;
}
.align-right {
    justify-content: flex-end;
}

// Borders
.border-top {
    border-top: 1px solid $color-border;
}
.border-bottom {
    border-bottom: 1px solid $color-border;
}
.border-left {
    border-left: 1px solid $color-border;
}
.border-right {
    border-right: 1px solid $color-border;
}

.margin-left {
    margin-left: 6px !important;
}
.margin-right {
    margin-right: 6px !important;
}
.margin-top {
    margin-top: 6px !important;
}
.margin-bottom {
    margin-bottom: 6px !important;
}
.margin-zero {
    margin: 0px !important;
}

.desktop {
    padding: 0 186px;
    max-width: 1440px;
    margin: 0 auto;
}
.tablet {
    padding: 0 96px;
}
.mobile {
    padding: 0 16px;
}

.chip-tag {
    padding: 4px 10px 4px 10px;
    border-radius: 30px;
    font-weight: $weight-400 !important;
    font-size: $size-11 !important;
    letter-spacing: 1px;
}
.label-tag {
    padding: 1px 6px 1px 6px;
    border-radius: 4px;
    font-weight: $weight-500 !important;
    font-size: $size-12 !important;
}
.post-tag {
    padding: 1px 4px 1px 4px;
    border-radius: 4px;
    font-weight: $weight-500 !important;
    font-size: $size-11 !important;
    background-color: $color-secondary;
}
.activated-tag {
    background-color: #00db00;
    color: $color-white !important;
}
.deactivated-tag {
    background-color: #eaeaee;
    color: $color-accent !important;
}
.submit-tag {
    border-radius: 4px;
    background-color: #e87630;
    color: $color-white !important;
}
.group-tag {
    border-radius: 4px;
    background-color: #74326f;
    color: $color-white !important;
}
.personal-tag {
    border-radius: 4px;
    background-color: #74326f;
    color: $color-white !important;
}
.expired-tag {
    border-radius: 4px;
    background-color: #f44336;
    color: $color-white !important;
}
.active-tag {
    border-radius: 4px;
    background-color: #00db00;
    color: $color-white !important;
}
.success-tag {
    background-color: #00db00;
    color: $color-white !important;
}
.theme-tag {
    background-color: $color-primary;
    color: $color-white !important;
}
.failed-tag {
    background-color: #f44336;
    color: $color-white !important;
}
.answermode-on-tag {
    border-radius: 4px;
    background-color: $color-accent;
    color: $color-white !important;
}
.answermode-off-tag {
    border-radius: 4px;
    background-color: rgba($color: $color-accent, $alpha: 0.5);
    color: $color-white !important;
}

.verified-icon {
    color: #00db00;
}
.pending-icon {
    color: #ebbb1f;
}
.error-icon {
    color: #f44336;
}

.primary-background {
    background-color: $color-primary !important;
}
.white-brackground {
    background-color: $color-white !important;
}

.mat-tooltip {
    background-color: $color-accent;
    font-size: $size-11;
    font-weight: $weight-400;
    padding: 4px 2px 4px 2px;
    margin: 5px !important;
}
.black-tooltip {
    background-color: $color-accent;
    font-size: $size-13;
    font-weight: $weight-400;
}
.black-small-tooltip {
    background-color: $color-accent;
    font-size: $size-11;
    font-weight: $weight-400;
    padding: 4px 2px 4px 2px;
    margin: 5px !important;
}

.mat-form-field {
    width: 100%;
    mat-label {
        font-weight: $weight-400;
        font-size: $size-13;
    }
    input {
        width: 100%;
        font-weight: $weight-400;
        font-size: $size-13;
    }
    .mat-select {
        width: 100%;
        font-weight: $weight-400 !important;
        font-size: $size-13 !important;
    }
    textarea {
        width: 100%;
        font-weight: $weight-400;
        font-size: $size-13;
        line-height: 23px;
        resize: none !important;
    }
}
.mat-select-panel {
    .mat-option {
        font-weight: $weight-400 !important;
        font-size: $size-13 !important;
    }
}
.mat-form-field-appearance-fill {
    .mat-form-field-flex {
        background-color: $color-accent;
    }
}
.mat-form-field-appearance-outline {
    .mat-form-field-outline {
        color: $color-accent;
    }
}

.mat-simple-snackbar {
    font-size: $size-13;
    font-weight: $weight-500;
}
.success-snackbar {
    background-color: $color-accent;
    margin-bottom: 0px !important;
    span {
        color: $color-white-100;
    }
    button {
        color: $color-white-100;
        font-size: $size-13;
        font-weight: $weight-500;
    }
}
.error-snackbar {
    background-color: $color-accent;
    margin-bottom: 0px !important;
    span {
        color: $color-white-100;
    }
    button {
        border: 1px solid $color-black;
        color: $color-white-100;
        font-size: $size-13;
        font-weight: $weight-500;
    }
}
.white-snackbar {
    background-color: $color-white;
    @include box-shadow(0px 2px 5px 0px rgba(0, 0, 0, 0.25));
    span {
        color: $color-black-100;
    }
    button {
        span {
            color: $color-primary;
        }
        color: $color-primary;
        font-size: $size-13;
        font-weight: $weight-500;
    }
}
.primary-snackbar {
    background-color: $color-primary;
    @include box-shadow(0px 2px 5px 0px rgba(0, 0, 0, 0.25));
    span {
        color: $color-white-100;
    }
    button {
        span {
            color: $color-white;
        }
        color: $color-white;
        font-size: $size-13;
        font-weight: $weight-500;
    }
}

.post-form-field {
    .mat-form-field-underline {
        display: none;
    }
    .mat-form-field-infix {
        padding: 12px 0px 10px 0px;
        border-top: 0px;
    }
    .mat-input-element::placeholder {
        color: $color-black-300 !important;
    }
    .mat-form-field-wrapper {
        padding-bottom: 0px;
        margin: 0px !important;
        border-radius: 10px !important;
    }
}

.comment-form-field {
    min-height: 20px !important;
    .mat-form-field-underline {
        display: none;
    }
    .mat-form-field-infix {
        padding: 12px 0px 10px 0px;
        border-top: 0px;
        .mat-input-element {
            min-height: 20px !important;
        }
    }
    .mat-input-element::placeholder {
        color: $color-black-300 !important;
    }
    .mat-form-field-wrapper {
        padding-bottom: 0px;
        margin: 0px !important;
        border-radius: 10px !important;
    }
}

.search-form-field {
    max-width: 250px;
    padding: 2px 2px 2px 12px;
    background-color: $color-white;
    border-radius: 30px;
    font-size: $size-13 !important;
    font-weight: $weight-400 !important;
    color: $color-accent;
    border: 1px solid $color-primary;
    .mat-form-field-underline {
        display: none;
    }
    .mat-form-field-infix {
        border-top: 0px;
    }
    .mat-form-field-wrapper {
        padding-bottom: 0px;
    }
    .mat-form-field-suffix {
        .mat-icon-button {
            height: 30px;
            width: 60px;
            background-color: $color-primary;
            border-radius: 30px;
            .mat-icon {
                width: unset;
                height: unset;
                line-height: unset;
                font-size: 18px !important;
                vertical-align: middle;
                color: $color-white;
            }
            &:hover {
                background-color: $color-primary;
            }
        }
    }
}

.sticky {
    position: fixed;
    top: 56px;
    > :first-child {
        margin-top: 16px;
        transition: margin-top 0.25s ease-in-out;
    }
}

.section-padding {
    padding: 16px 0px 16px 0px;
}

.container {
    .container-title {
        padding: 16px 0px 0px 0px;
        h1 {
            font-weight: $weight-400;
        }
        h2 {
            font-weight: $weight-600;
            margin: 0px 0px 10px 0px;
        }
        h4 {
            font-weight: $weight-600;
            text-transform: uppercase;
            color: $color-primary;
            margin: 0px 0px 10px 0px;
        }
    }
    .container-title-dark {
        padding: 16px 0px 0px 0px;
        h2 {
            font-weight: $weight-600;
            color: $color-white-100;
            margin: 0px 0px 10px 0px;
        }
        h4 {
            font-weight: $weight-600;
            text-transform: uppercase;
            color: $color-white-100;
            margin: 0px 0px 10px 0px;
        }
    }
    .container-body {
        p {
            font-size: $size-14;
            font-weight: $weight-400;
            color: $color-black-100;
            line-height: 26px;
            b {
                font-weight: $weight-600;
            }
            strong {
                font-size: $size-15;
                font-weight: $weight-700;
            }
            a {
                font-size: $size-14;
                font-weight: $weight-400;
                color: $color-black-100;
                outline: none;
                text-decoration: none;
            }
        }
        ul {
            padding: 0px 0px 0px 20px;
            margin: 0px;
            li {
                font-size: $size-14;
                font-weight: $weight-400;
                color: $color-black-100;
                line-height: 26px;
                a {
                    font-size: $size-14;
                    font-weight: $weight-400;
                    color: $color-black-100;
                    outline: none;
                    text-decoration: none;
                }
            }
        }
        small {
            font-size: $size-11;
            font-weight: $weight-400;
            color: $color-black-100;
            b {
                font-weight: $weight-600;
            }
        }
        button {
            font-weight: $weight-600;
            text-transform: uppercase;
            border-radius: 30px;
            line-height: unset;
            padding: 10px 20px 10px 20px;
        }
    }
}

.main-container {
    min-height: 600px;
    max-width: 1440px;
    margin: 16px auto;
}

.page-title-container {
    background-color: $color-white;
    .page-title {
        padding-top: 10px;
        padding-bottom: 10px;
        margin-top: 50px;
        .page-menus {
            font-size: $size-11;
            font-weight: $weight-400;
            a {
                text-decoration: none;
                outline: none;
                &:hover {
                    text-decoration: underline;
                }
            }
        }
        .page-heading {
            margin: 6px 0px 0px 0px;
            h3 {
                font-weight: $weight-500;
                color: $color-black-100;
                margin: 0px;
            }
        }
    }
}

.round-flat-button {
    border-radius: 30px !important;
    font-weight: $weight-500 !important;
    font-size: $size-13 !important;
    color: $color-white-100 !important;
    .mat-icon {
        font-size: 20px;
        width: unset;
        height: unset;
        line-height: unset;
        vertical-align: middle;
        margin-right: 4px;
        color: $color-white-100 !important;
    }
}

.round-stroked-button {
    border-radius: 30px !important;
    font-weight: $weight-500 !important;
    font-size: $size-13 !important;
    border: 1px solid $color-primary !important;
    .mat-icon {
        font-size: 20px;
        width: unset;
        height: unset;
        line-height: unset;
        vertical-align: middle;
        margin-right: 4px;
    }
}

.round-stroked-button-small {
    border-radius: 30px !important;
    font-weight: $weight-500 !important;
    font-size: $size-13 !important;
    border: 1px solid $color-accent !important;
    line-height: unset !important;
    padding: 4px 8px 4px 8px !important;
    .mat-icon {
        font-size: 18px;
        width: unset;
        height: unset;
        line-height: unset;
        vertical-align: middle;
        margin-right: 4px;
    }
}

.icon-small-button {
    width: 26px !important;
    height: 26px !important;
    line-height: unset !important;
    .mat-icon {
        width: unset;
        height: unset;
        line-height: unset;
        font-size: 16px;
        color: $color-accent;
    }
    &:hover {
        background-color: $color-secondary;
    }
}

.space-around-container {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    padding: 4px 10px 4px 10px;
    .icon-container {
        .circle-icon {
            width: 40px;
            height: 40px;
            background-color: $color-secondary;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-right: 10px;
            .mat-icon {
                width: unset;
                height: unset;
                line-height: unset;
                color: $color-accent;
                font-size: 20px;
            }
        }
    }
    .body-container {
        padding: 0px 0px 0px 0px;
        width: 100%;
    }
    .action-container {
        padding: 0px 0px 0px 0px;
        .mat-icon-button {
            .mat-icon {
                width: unset;
                height: unset;
                line-height: unset;
            }
            &:hover {
                background-color: $color-secondary;
            }
        }
    }
    &:hover {
        border-radius: 10px;
        background-color: $color-secondary;
    }
}

.space-around-border-container {
    display: flex;
    align-items: center;
    padding: 4px 0px 4px 0px;
    border-bottom: 1px solid $color-border;
    .icon-container {
        .circle-icon {
            width: 40px;
            height: 40px;
            background-color: $color-secondary;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-right: 10px;
            .mat-icon {
                width: unset;
                height: unset;
                line-height: unset;
                color: $color-accent;
                font-size: 20px;
            }
        }
    }
    .body-container {
        padding: 0px 0px 0px 0px;
        width: 100%;
    }
    .action-container {
        padding: 0px 0px 0px 0px;
        .mat-icon-button {
            .mat-icon {
                width: unset;
                height: unset;
                line-height: unset;
            }
            &:hover {
                background-color: $color-secondary;
            }
        }
    }
    &:last-child {
        border: none;
    }
}

.space-between-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 16px 10px 16px;
    border-bottom: 1px solid $color-border;
    .body-container {
        padding: 0px 0px 0px 0px;
        width: 100%;
    }
    .action-container {
        padding: 0px 0px 0px 0px;
        text-align: right;
        .mat-button {
            border-radius: 4px;
            font-size: $size-12;
            font-weight: $weight-500;
            padding: 2px 1px 2px 1px;
            line-height: unset;
            color: $color-black-100;
            &:hover {
                background-color: $color-secondary;
            }
        }
        small {
            display: inline-block;
            font-size: $size-11;
            font-weight: $weight-500;
            color: $color-black-300;
            b {
                font-weight: $weight-600;
            }
        }
    }
    &:hover {
        background-color: $color-secondary;
    }
}

.space-between-fields {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 10px 0px 10px 0px;
    border-top: 1px solid $color-border;
    .label-div {
        min-width: 200px;
        label {
            font-weight: $weight-400;
            font-size: $size-13;
            color: $color-black-300;
            margin: 0px;
        }
    }
    .field-div {
        h4 {
            font-size: $size-13;
            font-weight: $weight-500;
            color: $color-black-100;
            margin: 0px;
        }
    }
}

.field-values {
    label {
        font-weight: $weight-400;
        font-size: $size-11;
        color: $color-black-300;
        margin: 0px;
    }
    p {
        font-size: $size-13;
        font-weight: $weight-400;
        color: $color-black-100;
        margin: 0px;
        white-space: pre-wrap;
    }
    h4 {
        font-size: $size-14;
        font-weight: $weight-500;
        color: $color-black-100;
        margin: 0px;
    }
    small {
        display: block;
        font-size: $size-11;
        font-weight: $weight-500;
        color: $color-black-300;
        b {
            font-weight: $weight-600;
        }
    }
    span {
        display: block;
        font-size: $size-13;
        font-weight: $weight-400;
        color: $color-black-300;
        b {
            font-weight: $weight-500;
        }
    }
}

.input-label {
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    margin-top: 9px;
    h4 {
        font-size: $size-13;
        font-weight: $weight-400;
        color: $color-black-100;
        margin: 0px;
        padding: 6px 10px 6px 10px;
        border-radius: 10px;
        background-color: $color-secondary;
    }
}

.mat-autocomplete-panel {
    @include box-shadow(0px 2px 5px 0px rgba(0, 0, 0, 0.25));
    max-height: unset !important;
    border-bottom-left-radius: 10px !important;
    border-bottom-right-radius: 10px !important;
}

.default-panel-card {
    border-radius: 10px;
    background-color: $color-white;
    border: 1px solid $color-border;
    border-bottom: 2px solid $color-border;
    margin-bottom: 16px;
    overflow: hidden;
    width: 100%;
    .default-panel-header {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        padding: 10px 16px 10px 16px;
        border-bottom: 1px solid $color-border;
        h3 {
            font-weight: $weight-500;
            color: $color-black-100;
            margin: 0px 0px 0px 0px;
            line-height: normal;
            span {
                font-size: $size-13;
                font-weight: $weight-400;
                color: $color-black-100;
            }
            b {
                font-size: $size-13;
                font-weight: $weight-500;
                color: $color-black-100;
            }
        }
        small {
            font-size: $size-12;
            font-weight: $weight-500;
            color: $color-black-100;
            margin: 0px 0px 0px 0px;
            b {
                font-weight: $weight-600;
                color: $color-black-100;
            }
        }
    }
    .default-panel-container {
        padding: 0px 0px 0px 0px;
    }
    .default-panel-footer {
        padding: 0px 16px 16px 16px;
    }
}

.default-details-card {
    border-radius: 10px;
    background-color: $color-white;
    border: 1px solid $color-border;
    border-bottom: 2px solid $color-border;
    margin-bottom: 16px;
    overflow: hidden;
    .default-details-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 12px 16px 10px 16px;
        h4 {
            font-size: $size-14;
            font-weight: $weight-500;
            color: $color-black-100;
            margin: 0px;
        }
    }
    .default-details-container {
        padding: 0px 16px 10px 16px;
        p {
            font-size: $size-13;
            font-weight: $weight-400;
            color: $color-black-100;
            margin: 0px;
            white-space: pre-wrap;
        }
    }
    .default-details-footer {
        border-top: 1px solid $color-border;
        padding: 10px 16px 16px 16px;
        text-align: center;
        cursor: pointer;
        a {
            font-size: $size-14;
            font-weight: $weight-400;
            color: $color-primary;
            text-decoration: none;
            outline: none;
        }
        &:hover {
            background-color: $color-secondary;
            a {
                text-decoration: underline;
            }
        }
    }
}

.panel-card {
    padding: 16px 16px 16px 16px;
    .panel-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 4px 0px 4px 0px;
        h4 {
            font-size: $size-14;
            font-weight: $weight-500;
            color: $color-black-100;
            margin: 0px;
        }
    }
    .panel-container {
        p {
            font-size: $size-13;
            font-weight: $weight-400;
            color: $color-black-100;
        }
    }
}

.access-card {
    margin: 0 auto;
    border: 1px solid $color-border;
    border-bottom: 2px solid $color-border;
    background-color: $color-white;
    border-radius: 10px;
    max-width: 400px;
    padding: 30px;
    overflow: hidden;
    .access-card-header {
        text-align: center;
    }
    .access-card-body {
        padding: 5px;
    }
    .access-card-footer {
        padding: 5px;
        .note {
            text-align: center;
            small {
                font-weight: $weight-400;
                a {
                    font-weight: $weight-500;
                }
            }
        }
        .action {
            text-align: center;
            padding-top: 16px;
        }
        button {
            border-radius: 30px;
            font-size: $size-13;
            line-height: unset;
            padding: 5px 30px 5px 30px;
            height: 40px;
            margin-left: 6px;
        }
    }
    .auth-login {
        display: flex;
        justify-content: flex-start;
        flex-direction: column;
        .or-div {
            position: relative;
            border-bottom: 1px solid $color-border;
            margin: 20px 0px 20px 0px;
            padding-bottom: 10px;
            left: 0;
            right: 0;
            width: 100%;
            display: flex;
            justify-content: center;
            span {
                position: absolute;
                margin: 0 auto;
                background-color: $color-white;
                color: $color-black-300;
                font-weight: $weight-400;
                font-size: $size-13;
                padding: 0px 10px 0px 10px;
            }
        }
        .short-links {
            display: flex;
            align-items: center;
            justify-content: space-between;
            img {
                width: 172px;
                object-fit: cover;
            }
        }
    }
}

.news-card {
    border-radius: 10px;
    background-color: $color-white;
    border: 1px solid $color-border;
    border-bottom: 2px solid $color-border;
    margin-bottom: 16px;
    overflow: hidden;
    .news-header {
        padding: 6px 10px 6px 10px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        h4 {
            font-weight: $weight-500;
            margin: 0px;
        }
        .mat-icon {
            width: unset;
            height: unset;
            line-height: unset;
            font-size: 22px;
            color: $color-accent;
            cursor: pointer;
        }
    }
    .news-body {
        ul {
            margin: 0px;
            padding: 0px;
            list-style-type: none;
            li {
                padding: 6px 10px 6px 10px;
                cursor: pointer;
                .news-items {
                    display: flex;
                    justify-content: flex-start;
                    align-items: flex-start;
                    .bullets {
                        margin-right: 10px;
                        .mat-icon {
                            width: unset;
                            height: unset;
                            line-height: unset;
                            color: $color-accent;
                            font-size: 7px;
                        }
                    }
                    .news {
                        h4 {
                            font-size: $size-14;
                            font-weight: $weight-500;
                            margin: 0px;
                            display: -webkit-box;
                            -webkit-box-orient: vertical;
                            -webkit-line-clamp: 2;
                            color: $color-black-100;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            word-break: break-all;
                        }
                        span {
                            margin: 0px;
                            font-size: $size-11;
                            font-weight: $weight-400;
                            color: $color-black-100;
                            b {
                                font-weight: $weight-600;
                            }
                        }
                    }
                    .action {
                        padding: 0px;
                    }
                }
                &:hover {
                    background-color: $color-secondary;
                }
            }
        }
    }
}

.event-card {
    border-radius: 10px;
    background-color: $color-white;
    border: 1px solid $color-border;
    border-bottom: 2px solid $color-border;
    margin-bottom: 16px;
    overflow: hidden;
    a {
        outline: none;
        text-decoration: none;
    }
    .event-header {
        display: flex;
        align-items: center;
        justify-content: center;
        img {
            width: 100%;
            object-fit: cover;
        }
    }
    .event-footer {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 10px 16px 10px 16px;
        text-align: center;
        cursor: pointer;
        h4 {
            font-size: $size-14;
            font-weight: $weight-500;
            margin: 0px;
            color: $color-black-100;
        }
        a {
            font-size: $size-14;
            font-weight: $weight-400;
            color: $color-primary;
            text-decoration: none;
            outline: none;
        }
        &:hover {
            background-color: $color-secondary;
            a {
                text-decoration: underline;
            }
        }
    }
}

.top-action-card-list {
    .top-action-card {
        position: relative;
        cursor: pointer;
        border-bottom: 1px solid $color-border;
        .top-action-header {
            padding: 6px 16px 0px 16px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            h4 {
                font-size: $size-13;
                font-weight: $weight-500;
                color: $color-black-100;
                margin: 6px 0px 6px 0px;
            }
            .actions {
                .mat-icon-button {
                    width: 30px;
                    height: 30px;
                    line-height: unset;
                    background-color: $color-secondary;
                    margin-left: 6px;
                    .mat-icon {
                        width: unset;
                        height: unset;
                        line-height: unset;
                        font-size: 20px;
                        color: $color-accent;
                    }
                    &:hover {
                        background-color: $color-secondary;
                    }
                }
            }
        }
        .top-action-container {
            padding: 0px 16px 6px 16px;
            p {
                font-size: $size-13;
                font-weight: $weight-400;
                color: $color-black-300;
                margin: 0px;
            }
        }
    }
    .active {
        background-color: $color-secondary;
        margin-top: -1px;
        border-top: 1px solid $color-accent;
        border-bottom: 1px solid $color-accent;
        &::after {
            content: "";
            width: 0px;
            height: 0px;
            position: absolute;
            top: 42%;
            right: 0;
            border: 8px solid transparent;
            border-right-color: $color-accent;
        }
        h4 {
            color: $color-primary !important;
        }
    }
}

.action-card {
    border-radius: 10px;
    background-color: $color-white;
    border: 1px solid $color-border;
    border-bottom: 2px solid $color-border;
    margin-bottom: 16px;
    overflow: hidden;
    .action-header {
        padding: 10px 10px 10px 10px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        h5 {
            font-size: $size-13;
            font-weight: $weight-500;
            color: $color-primary;
            margin: 0px;
        }
        .mat-icon {
            width: unset;
            height: unset;
            line-height: unset;
            font-size: 22px;
            color: $color-accent;
            cursor: pointer;
        }
    }
    .action-body {
        ul {
            margin: 0px;
            padding: 0px;
            list-style-type: none;
            li {
                padding: 4px 10px 4px 10px;
                cursor: pointer;
                .action-items {
                    display: flex;
                    justify-content: flex-start;
                    align-items: flex-start;
                    .bullets {
                        margin-right: 10px;
                        .mat-icon {
                            width: unset;
                            height: unset;
                            line-height: unset;
                            color: $color-accent;
                            font-size: 7px;
                        }
                    }
                    .news {
                        h4 {
                            font-size: $size-13;
                            font-weight: $weight-500;
                            display: -webkit-box;
                            -webkit-box-orient: vertical;
                            -webkit-line-clamp: 1;
                            color: $color-black-100;
                            margin: 0px;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            word-break: break-all;
                        }
                        span {
                            margin: 0px;
                            font-size: $size-11;
                            font-weight: $weight-400;
                            color: $color-black-100;
                            b {
                                font-weight: $weight-600;
                            }
                        }
                    }
                    .action {
                        padding: 0px;
                    }
                }
                &:hover {
                    background-color: $color-secondary;
                }
            }
            .active {
                background-color: $color-secondary;
            }
        }
        .action {
            background-color: $color-secondary;
            padding: 10px 10px 10px 10px;
            border-top: 1px solid $color-border;
        }
    }
}

.empty-list {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 30px 16px 30px 16px;
    img {
        width: 240px;
        object-fit: cover;
    }
    h4 {
        margin: 20px 0px 0px 0px;
        color: $color-black-100;
        font-weight: $weight-500;
    }
    small {
        color: $color-black-100;
        font-size: $size-13;
        font-weight: $weight-400;
    }
    span {
        color: $color-black-100;
        font-size: $size-13;
        font-weight: $weight-500;
    }
}
.empty-data {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border: 1px dashed rgba($color: $color-primary, $alpha: 0.6);
    padding: 36px 16px 36px 16px;
    border-radius: 4px;
    .mat-icon {
        width: unset;
        height: unset;
        line-height: unset;
        color: $color-accent;
    }
    h3 {
        margin: 10px 0px 10px 0px;
        font-weight: $weight-600;
        color: $color-accent;
    }
    p {
        font-size: $size-13;
        font-weight: $weight-400;
        color: $color-accent;
        line-height: 24px;
    }
}

.logo-image-100 {
    width: 100px;
    overflow: hidden;
    margin: 5px auto;
    img {
        width: 100%;
        object-fit: cover;
    }
}
.logo-image-160 {
    width: 160px;
    overflow: hidden;
    margin: 5px auto;
    img {
        width: 100%;
        object-fit: cover;
    }
}
.logo-image-130 {
    width: 100px;
    overflow: hidden;
    margin: 5px auto;
    img {
        width: 100%;
        object-fit: cover;
    }
}

.divide-two {
    display: flex;
    justify-content: space-between;
}

.dropdown-menu {
    margin-top: 16px;
    border-radius: 10px !important;
    z-index: 9999 !important;
    @include box-shadow(0px 2px 5px 0px rgba(0, 0, 0, 0.25));
    .profile-section {
        padding: 16px;
        display: flex;
        flex-direction: row;
        .profile-photo {
            margin-right: 10px;
            .profile-photo-image {
                width: 50px;
                height: 50px;
                overflow: hidden;
                margin: 0 auto;
                border-radius: 50%;
                background-color: $color-white;
                img {
                    height: 100%;
                    object-fit: contain;
                }
            }
            .profile-photo-div {
                background-color: rgba($color: $color-primary, $alpha: 1);
                width: 50px;
                height: 50px;
                border-radius: 50%;
                overflow: hidden;
                margin: 0 auto;
                display: flex;
                justify-content: center;
                align-items: center;
                .mat-subheading-2 {
                    margin: auto 0 !important;
                    font-weight: $weight-500;
                    color: $color-white-100;
                }
            }
        }
        .profile-info {
            .mat-body-1 {
                font-size: $size-13;
                color: $color-black-100;
                font-weight: $weight-400;
            }
            .mat-body-2 {
                color: $color-black-100;
                font-weight: $weight-500;
            }
            .mat-stroked-button {
                border-radius: 30px;
                font-size: $size-12;
                font-weight: $weight-500;
                padding: 4px 10px 4px 10px;
                line-height: unset;
                border-color: $color-primary;
                margin-top: 6px;
            }
        }
    }
    .mat-menu-content {
        padding: 0px !important;
    }
    .button-list-menus {
        padding: 6px;
        .action-menu {
            display: flex;
            align-items: center;
            justify-content: space-between;
            .menu {
                .mat-icon {
                    color: $color-accent !important;
                    margin: 0px 10px 0px 0px;
                    width: unset;
                    height: unset;
                    background-color: $color-secondary;
                    border-radius: 50%;
                    font-size: 20px;
                    padding: 6px;
                }
                span {
                    color: $color-black-100;
                    font-size: $size-14;
                    font-weight: $weight-500;
                }
            }
            .action {
                text-align: right;
                .mat-icon {
                    width: unset;
                    height: unset;
                    line-height: unset;
                    color: $color-accent;
                }
            }
        }
    }
    .mat-menu-item {
        border-radius: 10px;
        .mat-icon {
            margin: 0px;
        }
        &:hover {
            background-color: $color-secondary !important;
        }
    }
    .dropdown-item {
        background-color: transparent !important;
        text-decoration: none;
    }
    .active {
        background-color: $color-secondary !important;
    }
}

.actor-data {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    .actor-photo {
        margin-right: 10px;
        .photo-image {
            width: 54px;
            height: 54px;
            overflow: hidden;
            border-radius: 50%;
            background-color: $color-white;
            img {
                height: 100%;
                object-fit: contain;
            }
        }
        .photo-div {
            background-color: rgba($color: $color-primary, $alpha: 1);
            width: 54px;
            height: 54px;
            border-radius: 50%;
            overflow: hidden;
            display: flex;
            justify-content: center;
            align-items: center;
            .mat-subheading-2 {
                margin: auto 0 !important;
                font-weight: $weight-500;
                color: $color-white-100;
            }
        }
    }
    .actor-details {
        flex-grow: 9;
        h4 {
            font-size: $size-14 !important;
            font-weight: $weight-600 !important;
            margin: 0px;
            display: inline-block;
            line-height: 0px;
            cursor: pointer;
            outline: none;
            &:hover {
                text-decoration: underline;
                color: $color-primary !important;
            }
        }
        span {
            font-size: $size-12 !important;
            font-weight: $weight-400 !important;
            color: $color-black-300 !important;
            line-height: 0px;
            margin: 0px;
        }
        small {
            font-size: $size-11 !important;
            font-weight: $weight-400 !important;
            color: $color-black-300 !important;
            line-height: 0px;
            margin: 0px;
        }
    }
    .actor-actions {
        .mat-icon-button {
            width: 30px;
            height: 30px;
            line-height: unset;
            background-color: $color-secondary;
            .mat-icon {
                width: unset;
                height: unset;
                line-height: unset;
                font-size: 20px;
                color: $color-accent;
            }
            &:hover {
                background-color: $color-secondary;
            }
        }
    }
}

.assignment-data {
    display: flex;
    justify-content: space-evenly;
    .assignment-photo {
        margin-right: 16px;
        .mat-icon {
            width: unset;
            height: unset;
            font-size: 22px;
            margin-top: 2px;
        }
    }
    .assignment-details {
        flex-grow: 9;
        h4 {
            font-size: $size-14 !important;
            font-weight: $weight-600 !important;
            margin: 0px;
            display: inline-block;
            cursor: pointer;
            outline: none;
            line-height: normal;
            &:hover {
                text-decoration: underline;
                color: $color-primary !important;
            }
        }
        span {
            font-size: $size-12;
            font-weight: $weight-400;
            color: $color-black-300;
            line-height: 0px;
            margin: 0px;
            b {
                font-weight: $weight-600 !important;
            }
        }
        small {
            font-size: $size-11 !important;
            font-weight: $weight-400 !important;
            color: $color-black-300 !important;
            line-height: 0px;
            margin: 0px;
        }
        .count-label {
            .active {
                background-color: $color-success;
                font-size: $size-11;
                font-weight: $weight-500;
                color: $color-white;
                padding: 4px 8px 4px 8px;
                border-radius: 4px 0px 0px 4px;
            }
            .expired {
                background-color: $color-danger;
                font-size: $size-11;
                font-weight: $weight-500;
                color: $color-white;
                padding: 4px 8px 4px 8px;
                border-radius: 4px 0px 0px 4px;
            }
        }
        .mat-flat-button {
            span {
                font-size: $size-13;
                font-weight: $weight-500;
                color: $color-white;
            }
        }
    }
    .assignment-actions {
        .mat-icon-button {
            width: 30px;
            height: 30px;
            line-height: unset;
            background-color: $color-secondary;
            .mat-icon {
                width: unset;
                height: unset;
                line-height: unset;
                font-size: 20px;
                color: $color-accent;
            }
            &:hover {
                background-color: $color-secondary;
            }
        }
    }
}

.group-data {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    .group-photo {
        margin-right: 10px;
        .photo-image {
            width: 54px;
            height: 54px;
            overflow: hidden;
            border-radius: 50%;
            background-color: $color-white;
            img {
                height: 100%;
                object-fit: contain;
            }
        }
    }
    .group-details {
        flex-grow: 9;
        h4 {
            font-size: $size-14 !important;
            font-weight: $weight-600 !important;
            margin: 0px;
            display: inline-block;
            cursor: pointer;
            &:hover {
                text-decoration: underline;
                color: $color-primary !important;
            }
        }
        span {
            font-size: $size-12 !important;
            font-weight: $weight-400 !important;
            color: $color-black-300 !important;
            line-height: 0px;
            margin: 0px;
        }
        small {
            font-size: $size-11 !important;
            font-weight: $weight-400 !important;
            color: $color-black-300 !important;
            line-height: 0px;
            margin: 0px;
        }
        b {
            font-size: $size-13 !important;
            font-weight: $weight-400 !important;
            color: $color-black-300 !important;
            line-height: 0px;
            margin: 0px;
        }
        .round-stroked-button {
            span {
                color: $color-primary !important;
                font-weight: $weight-500 !important;
                font-size: $size-13 !important;
            }
        }
    }
    .group-actions {
        .mat-icon-button {
            width: 30px;
            height: 30px;
            line-height: unset;
            background-color: $color-secondary;
            .mat-icon {
                width: unset;
                height: unset;
                line-height: unset;
                font-size: 20px;
                color: $color-accent;
            }
            &:hover {
                background-color: $color-secondary;
            }
        }
    }
}

.info-data {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .info-details {
        h4 {
            font-size: $size-14 !important;
            font-weight: $weight-600 !important;
            margin: 0px;
        }
        span {
            font-size: $size-12 !important;
            font-weight: $weight-400 !important;
            color: $color-black-300 !important;
            line-height: 0px;
            margin: 0px;
        }
        small {
            font-size: $size-11 !important;
            font-weight: $weight-400 !important;
            color: $color-black-300 !important;
            line-height: 0px;
            margin: 0px;
        }
        b {
            font-size: $size-13 !important;
            font-weight: $weight-400 !important;
            color: $color-black-300 !important;
            line-height: 0px;
            margin: 0px;
        }
    }
    .info-actions {
        .mat-icon-button {
            width: 30px;
            height: 30px;
            line-height: unset;
            background-color: $color-secondary;
            .mat-icon {
                width: unset;
                height: unset;
                line-height: unset;
                font-size: 20px;
                color: $color-accent;
            }
            &:hover {
                background-color: $color-secondary;
            }
        }
    }
}

.fields-row {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border-bottom: 1px dashed $color-border;
    padding: 10px 16px 10px 16px;
    gap: 16px;
    .row-1 {
        flex: 0.1;
    }
    .row-2 {
        flex: 0.9;
        .item-card {
            width: 200px;
            display: inline-block;
            border: 1px solid $color-border;
            border-bottom: 2px solid $color-border;
            border-radius: 4px;
            padding: 10px;
            margin: 0px 10px 10px 0px;
            .item {
                display: flex;
                justify-content: flex-start;
                align-items: flex-start;
                .item-photo {
                    margin-right: 10px;
                    .item-image {
                        width: 44px;
                        height: 44px;
                        overflow: hidden;
                        border-radius: 50%;
                        background-color: $color-white;
                        img {
                            height: 100%;
                            object-fit: contain;
                        }
                    }
                    .profile-photo-div {
                        background-color: rgba($color: $color-primary, $alpha: 1);
                        width: 54px;
                        height: 54px;
                        border-radius: 50%;
                        overflow: hidden;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        .mat-subheading-2 {
                            margin: auto 0 !important;
                            font-weight: $weight-500;
                            color: $color-white-100;
                        }
                    }
                }
                .item-details {
                    h4 {
                        font-size: $size-14;
                        font-weight: $weight-500;
                        margin: 0px;
                        display: block;
                        cursor: pointer;
                        outline: none;
                        line-height: normal;
                        display: -webkit-box;
                        -webkit-box-orient: vertical;
                        -webkit-line-clamp: 1;
                        cursor: pointer;
                        color: $color-black-100;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        word-break: break-word;
                        &:hover {
                            color: $color-primary;
                        }
                    }
                    span {
                        display: block;
                        font-size: $size-12;
                        font-weight: $weight-400;
                        color: $color-black-300;
                        margin: 0px;
                        line-height: normal;
                        display: -webkit-box;
                        -webkit-box-orient: vertical;
                        -webkit-line-clamp: 1;
                        cursor: pointer;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        word-break: break-word;
                    }
                }
            }
        }
    }
    label {
        font-size: $size-13;
        font-weight: $weight-400;
        color: $color-black-300;
    }
    h4 {
        font-size: $size-14;
        font-weight: $weight-600;
        color: $color-black-100;
        margin: 0px;
    }
}

.counter-title {
    padding: 10px 16px 10px 16px;
    h1 {
        font-weight: $weight-600;
        color: $color-black-100;
        margin: 0px;
    }
    h3 {
        font-size: $size-13;
        font-weight: $weight-500;
        color: $color-black-100;
        margin: 0px;
    }
}

.suggestion-card {
    padding: 10px 10px 10px 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .suggestion-actor {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        .suggestion-actor-photo {
            margin-right: 10px;
            .profile-photo-image {
                width: 54px;
                height: 54px;
                overflow: hidden;
                border-radius: 50%;
                background-color: $color-white;
                img {
                    height: 100%;
                    object-fit: contain;
                }
            }
            .profile-photo-div {
                background-color: rgba($color: $color-primary, $alpha: 1);
                width: 54px;
                height: 54px;
                border-radius: 50%;
                overflow: hidden;
                display: flex;
                justify-content: center;
                align-items: center;
                .mat-subheading-2 {
                    margin: auto 0 !important;
                    font-weight: $weight-500;
                    color: $color-white-100;
                }
            }
        }
        .suggestion-actor-details {
            h4 {
                font-size: $size-14;
                font-weight: $weight-500;
                margin: 0px;
                display: inline-block;
                line-height: 0px;
                cursor: pointer;
                outline: none;
                &:hover {
                    text-decoration: underline;
                    color: $color-primary;
                }
            }
            span {
                font-size: $size-12;
                font-weight: $weight-400;
                color: $color-black-300;
                line-height: 0px;
                margin: 0px;
            }
            small {
                font-size: $size-11;
                font-weight: $weight-400;
                color: $color-black-300;
                line-height: 0px;
                margin: 0px;
            }
            label {
                font-size: $size-12;
                font-weight: $weight-400;
                margin: 0px;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 2;
                color: $color-black-300;
                overflow: hidden;
                text-overflow: ellipsis;
                word-break: break-all;
            }
            .mat-stroked-button {
                margin-top: 4px;
                span {
                    font-size: $size-13;
                    font-weight: $weight-500;
                    color: $color-primary;
                }
            }
        }
    }
    .suggestion-action {
        .mat-icon-button {
            width: 30px;
            height: 30px;
            line-height: unset;
            .mat-icon {
                width: unset;
                height: unset;
                line-height: unset;
            }
            &:hover {
                background-color: $color-secondary;
            }
        }
    }
}

.search-result-card {
    border-radius: 10px;
    background-color: $color-white;
    border: 1px solid $color-border;
    border-bottom: 2px solid $color-border;
    padding: 10px 0px 10px 0px;
    margin-bottom: 16px;
    overflow: hidden;
    .search-result {
        padding: 10px 16px 10px 16px;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        border-bottom: 1px solid $color-border;
        .search-result-item {
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
            flex-grow: 9;
            .search-result-item-photo {
                margin-right: 10px;
                .item-photo-image {
                    width: 54px;
                    height: 54px;
                    overflow: hidden;
                    border-radius: 50%;
                    background-color: $color-white;
                    img {
                        height: 100%;
                        object-fit: contain;
                    }
                }
                .item-photo-div {
                    background-color: rgba($color: $color-primary, $alpha: 1);
                    width: 54px;
                    height: 54px;
                    border-radius: 50%;
                    overflow: hidden;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    .mat-subheading-2 {
                        margin: auto 0 !important;
                        font-weight: $weight-500;
                        color: $color-white-100;
                    }
                }
            }
            .search-result-item-details {
                h4 {
                    font-size: $size-15;
                    font-weight: $weight-500;
                    margin: 0px;
                    display: inline-block;
                    cursor: pointer;
                    &:hover {
                        text-decoration: underline;
                        color: $color-primary;
                    }
                }
                span {
                    display: block;
                    font-size: $size-13;
                    font-weight: $weight-400;
                    color: $color-black-100;
                    margin: 0px;
                }
                small {
                    font-size: $size-11;
                    font-weight: $weight-400;
                    color: $color-black-300;
                    margin: 0px;
                }
                .mat-body-3 {
                    display: inline-block;
                    cursor: pointer;
                    font-size: $size-11;
                    font-weight: $weight-400;
                    color: $color-black-100;
                    margin: 0px;
                    outline: none;
                    .mat-icon {
                        height: unset;
                        width: unset;
                        line-height: unset;
                        font-size: 17px;
                        font-weight: $weight-400;
                        color: $color-accent;
                        vertical-align: middle;
                    }
                    &:hover {
                        text-decoration: underline;
                    }
                }
            }
        }
        .search-result-item-action {
            .mat-icon-button {
                width: 30px;
                height: 30px;
                line-height: unset;
                .mat-icon {
                    width: unset;
                    height: unset;
                    line-height: unset;
                }
                &:hover {
                    background-color: $color-secondary;
                }
            }
        }
        &:last-child {
            border: none;
        }
    }
}

.identity-card-list {
    padding: 16px 16px 16px 16px;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 10px;
    min-width: 0px;
    .identity-card {
        border-radius: 10px;
        background-color: $color-white;
        border: 1px solid $color-border;
        border-bottom: 2px solid $color-border;
        overflow: hidden;
        width: 176px;
        .image-section {
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            .banner {
                width: 100%;
                height: 80px;
                overflow: hidden;
                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }
            .photo {
                position: absolute;
                top: 20%;
                left: 0px;
                right: 0px;
                .photo-image {
                    cursor: pointer;
                    width: 100px;
                    height: 100px;
                    overflow: hidden;
                    margin: 0 auto;
                    border: 2px solid $color-white;
                    border-radius: 50%;
                    background-color: $color-white;
                    img {
                        height: 100%;
                        object-fit: contain;
                    }
                }
                .photo-div {
                    cursor: pointer;
                    background-color: rgba($color: $color-primary, $alpha: 1);
                    width: 100px;
                    height: 100px;
                    border-radius: 50%;
                    overflow: hidden;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin: 0 auto;
                    border: 2px solid $color-white;
                    .mat-title {
                        margin: auto 0 !important;
                        color: $color-white-100;
                    }
                }
            }
        }
        .details {
            margin: 50px 4px 10px 4px;
            text-align: center;
            .mat-body-2 {
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 1;
                cursor: pointer;
                font-size: $size-15;
                font-weight: $weight-500;
                color: $color-black-100;
                margin: 0px;
                height: 24px;
                overflow: hidden;
                text-overflow: ellipsis;
                word-break: break-all;
            }
            .mat-body-1 {
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 2;
                cursor: pointer;
                padding: 0px 10px 0px 10px;
                font-size: $size-13;
                font-weight: $weight-400;
                color: $color-black-100;
                height: 40px;
                overflow: hidden;
                text-overflow: ellipsis;
                word-break: break-word;
            }
            .mat-body-3 {
                padding: 6px 10px 0px 10px;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 1;
                cursor: pointer;
                font-size: $size-11;
                font-weight: $weight-400;
                color: $color-black-100;
                margin: 0px;
                height: 18px;
                overflow: hidden;
                text-overflow: ellipsis;
                word-break: break-all;
                outline: none;
                .mat-icon {
                    height: unset;
                    width: unset;
                    line-height: unset;
                    font-size: 17px;
                    font-weight: $weight-400;
                    color: $color-accent;
                    vertical-align: middle;
                }
                &:hover {
                    text-decoration: underline;
                }
            }
            .mat-stroked-button {
                margin-top: 10px;
                border-radius: 30px;
                border: 1px solid $color-primary;
                font-size: $size-13;
                font-weight: $weight-500;
                color: $color-primary;
                line-height: unset;
                padding: 6px 20px 6px 20px;
                height: 34px;
            }
        }
        &:hover {
            @include box-shadow(0px 2px 5px 0px rgba(0, 0, 0, 0.25));
        }
    }
    .assignment-card {
        border-radius: 10px;
        background-color: $color-white;
        border: 1px solid $color-border;
        border-bottom: 2px solid $color-border;
        overflow: hidden;
        width: 246px;
        .image-section {
            position: relative;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            height: 70px;
            padding: 0px 10px 0px 10px;
            .mat-icon {
                height: unset;
                width: unset;
                line-height: unset;
                font-size: 40px;
                color: $color-theme;
                vertical-align: middle;
            }
            .count-label {
                position: absolute;
                top: 10px;
                right: 0;
                .active {
                    background-color: $color-success;
                    font-size: $size-11;
                    font-weight: $weight-500;
                    color: $color-white;
                    padding: 4px 8px 4px 8px;
                    border-radius: 4px 0px 0px 4px;
                }
                .expired {
                    background-color: $color-danger;
                    font-size: $size-11;
                    font-weight: $weight-500;
                    color: $color-white;
                    padding: 4px 8px 4px 8px;
                    border-radius: 4px 0px 0px 4px;
                }
            }
        }
        .details {
            margin: 0px 10px 10px 10px;
            text-align: left;
            .mat-body-2 {
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 1;
                cursor: pointer;
                font-size: $size-15;
                font-weight: $weight-500;
                color: $color-black-100;
                margin: 0px;
                height: 24px;
                overflow: hidden;
                text-overflow: ellipsis;
                word-break: break-all;
            }
            .mat-body-1 {
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 2;
                cursor: pointer;
                font-size: $size-13;
                font-weight: $weight-400;
                color: $color-black-100;
                height: 40px;
                overflow: hidden;
                text-overflow: ellipsis;
                word-break: break-word;
                b {
                    color: $color-black-300;
                    font-weight: $weight-600;
                }
            }
            .mat-body-3 {
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 1;
                cursor: pointer;
                font-size: $size-11;
                font-weight: $weight-400;
                color: $color-black-100;
                margin: 0px;
                height: 18px;
                overflow: hidden;
                text-overflow: ellipsis;
                word-break: break-all;
                outline: none;
                .mat-icon {
                    height: unset;
                    width: unset;
                    line-height: unset;
                    font-size: 17px;
                    font-weight: $weight-400;
                    color: $color-accent;
                    vertical-align: middle;
                }
                &:hover {
                    text-decoration: underline;
                }
            }
            .duration {
                padding: 10px 0px 6px 0px;
                border-bottom: 1px solid $color-border;
                margin-bottom: 10px;
                h3 {
                    cursor: pointer;
                    font-size: $size-12;
                    font-weight: $weight-600;
                    color: $color-black-300;
                    margin: 10px 0px 0px 0px;
                    b {
                        color: $color-black-100;
                        font-weight: $weight-600;
                    }
                }
            }
            .mat-stroked-button {
                border-radius: 30px;
                border: 1px solid $color-accent;
                font-size: $size-13;
                font-weight: $weight-500;
                color: $color-accent;
                line-height: unset;
                padding: 6px 20px 6px 20px;
                height: 34px;
            }
            .mat-flat-button {
                border-radius: 30px;
                border: 1px solid $color-primary;
                font-size: $size-13;
                font-weight: $weight-500;
                line-height: unset;
                padding: 6px 20px 6px 20px;
                height: 34px;
            }
        }
        &:hover {
            @include box-shadow(0px 2px 5px 0px rgba(0, 0, 0, 0.25));
        }
    }
}

.post-card {
    border-radius: 10px;
    background-color: $color-white;
    border: 1px solid $color-border;
    border-bottom: 2px solid $color-border;
    margin-bottom: 16px;
    padding: 10px 16px 10px 16px;
    overflow: hidden;
    .post-action {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        .post-creator {
            margin-right: 10px;
            .profile-photo-image {
                width: 54px;
                height: 54px;
                overflow: hidden;
                border-radius: 50%;
                background-color: $color-white;
                img {
                    height: 100%;
                    object-fit: contain;
                }
            }
            .profile-photo-div {
                background-color: rgba($color: $color-primary, $alpha: 1);
                width: 54px;
                height: 54px;
                border-radius: 50%;
                overflow: hidden;
                display: flex;
                justify-content: center;
                align-items: center;
                .mat-subheading-2 {
                    margin: auto 0 !important;
                    font-weight: $weight-500;
                    color: $color-white-100;
                }
            }
        }
        .post-button {
            flex-grow: 1;
            .mat-stroked-button {
                border-radius: 30px;
                line-height: unset;
                padding: 14px 16px 14px 16px;
                width: 100%;
                text-align: left;
                font-size: $size-13;
                font-weight: $weight-500;
                color: $color-black-300;
            }
        }
    }
    .post-attachment {
        .post-attachment-options {
            padding: 10px 0px 0px 0px;
            .photo {
                font-size: $size-13;
                font-weight: $weight-500;
                color: $color-black-300;
                .mat-icon {
                    color: #66d857;
                }
            }
            .video {
                font-size: $size-13;
                font-weight: $weight-500;
                color: $color-black-300;
                .mat-icon {
                    color: #579ad8;
                }
            }
            .audio {
                font-size: $size-13;
                font-weight: $weight-500;
                color: $color-black-300;
                .mat-icon {
                    color: #e69a43;
                }
            }
            .document {
                font-size: $size-13;
                font-weight: $weight-500;
                color: $color-black-300;
                .mat-icon {
                    color: #d85757;
                }
            }
        }
    }
}

.posts-timeline {
    .default-post-card {
        border-radius: 10px;
        background-color: $color-white;
        border: 1px solid $color-border;
        border-bottom: 2px solid $color-border;
        margin-bottom: 16px;
        overflow: hidden;
        .default-post-card-note {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin: 6px 16px 0px 16px;
            padding-bottom: 6px;
            border-bottom: 1px solid $color-border;
            .post-note {
                a {
                    font-size: $size-12;
                    font-weight: $weight-500;
                    color: $color-black-100;
                    outline: none;
                    text-decoration: none;
                    line-height: normal;
                    &:hover {
                        text-decoration: underline;
                        color: $color-primary;
                    }
                }
                span {
                    font-size: $size-11;
                    font-weight: $weight-400;
                    color: $color-black-300;
                    line-height: normal;
                }
            }
            .post-action {
                .mat-icon-button {
                    width: 30px;
                    height: 30px;
                    line-height: unset;
                    .mat-icon {
                        width: unset;
                        height: unset;
                        line-height: unset;
                    }
                    &:hover {
                        background-color: $color-secondary;
                    }
                }
            }
        }
        .default-post-card-header {
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
            margin: 10px 16px 6px 16px;
            min-width: 0;
            .post-actor {
                display: flex;
                justify-content: flex-start;
                align-items: flex-start;
                cursor: pointer;
                min-width: 0;
                .post-creator-photo {
                    margin-right: 10px;
                    .profile-photo-image {
                        width: 54px;
                        height: 54px;
                        overflow: hidden;
                        border-radius: 50%;
                        background-color: $color-white;
                        img {
                            height: 100%;
                            object-fit: contain;
                        }
                    }
                    .profile-photo-div {
                        background-color: rgba($color: $color-primary, $alpha: 1);
                        width: 54px;
                        height: 54px;
                        border-radius: 50%;
                        overflow: hidden;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        .mat-subheading-2 {
                            margin: auto 0 !important;
                            font-weight: $weight-500;
                            color: $color-white-100;
                        }
                    }
                }
                .post-creator-details {
                    flex-grow: 1;
                    min-width: 0;
                    h4 {
                        font-size: $size-14;
                        font-weight: $weight-500;
                        margin: 0px;
                        outline: none;
                        line-height: normal;
                        max-width: 380px;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                        display: block;
                        overflow: hidden;
                        line-height: normal;
                        &:hover {
                            text-decoration: underline;
                            color: $color-primary;
                        }
                    }
                    span {
                        font-size: $size-11;
                        font-weight: $weight-400;
                        color: $color-black-300;
                        line-height: normal;
                        margin: -2px 0px -2px 0px;
                        max-width: 380px;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                        display: block;
                        overflow: hidden;
                    }
                    small {
                        font-size: $size-11;
                        font-weight: $weight-400;
                        color: $color-black-300;
                        margin: 0px 0px 0px 0px;
                        line-height: normal;
                        // display: block;
                    }
                    .mat-icon {
                        color: $color-black-300;
                        font-size: 16px;
                        width: unset;
                        height: unset;
                        line-height: unset;
                        vertical-align: middle;
                    }
                    b {
                        font-size: $size-13;
                        font-weight: $weight-500;
                        color: $color-black-100;
                    }
                    .mat-flat-button {
                        padding: 4px 6px 4px 6px;
                        line-height: unset;
                        margin-left: 10px;
                        span {
                            font-size: $size-13;
                            font-weight: $weight-500;
                            color: $color-primary;
                        }
                        .mat-icon {
                            color: $color-primary;
                            font-size: 18px;
                            width: unset;
                            height: unset;
                            line-height: unset;
                            vertical-align: middle;
                        }
                        &:hover {
                            background-color: $color-secondary;
                        }
                    }
                }
            }
            .post-action {
                margin-left: 10px;
                .mat-icon-button {
                    width: 30px;
                    height: 30px;
                    line-height: unset;
                    .mat-icon {
                        width: unset;
                        height: unset;
                        line-height: unset;
                    }
                    &:hover {
                        background-color: $color-secondary;
                    }
                }
            }
        }
        .default-post-card-container {
            .post-text {
                padding: 4px 16px 6px 16px;
                position: relative;
                span {
                    font-size: $size-13;
                    font-weight: $weight-400;
                    color: $color-black-100;
                    cursor: default;
                    white-space: pre-wrap;
                    display: block;
                    a {
                        color: $color-primary;
                        font-size: $size-13;
                        font-weight: $weight-500;
                        cursor: pointer;
                        outline: none;
                        text-decoration: none;
                        &:hover {
                            text-decoration: underline;
                        }
                    }
                    b {
                        font-weight: $weight-600;
                    }
                }
                .see-more {
                    position: absolute;
                    bottom: 2px;
                    right: 0px;
                    margin: 0px 16px 0px 0px;
                    background-color: $color-white;
                    color: $color-black-300;
                    font-size: $size-13;
                    font-weight: $weight-400;
                    cursor: pointer;
                    outline: none;
                    text-decoration: none;
                    padding: 0px 0px 0px 8px;
                    &:hover {
                        text-decoration: underline;
                    }
                }
            }
            .post-assignment {
                padding: 6px 16px 6px 10px;
                margin: 4px 16px 6px 16px;
                border: 1px solid $color-primary;
                border-radius: 10px;
                .assignment-data {
                    .assignment-photo {
                        margin-right: 10px;
                        .mat-icon {
                            font-size: 36px;
                            color: $color-theme;
                        }
                    }
                }
            }
            .post-content-hide {
                max-height: 56px;
                overflow: hidden;
            }
            .post-attached-files {
                padding: 0px 0px 0px 0px;
            }
            .external-metadata {
                a {
                    outline: none;
                    text-decoration: none;
                    .metadata-container {
                        background-color: $color-secondary;
                        .metadata-media {
                            img {
                                width: 100%;
                                object-fit: contain;
                            }
                        }
                        .metadata-body {
                            padding: 4px 16px 4px 16px;
                            h4 {
                                font-size: $size-13;
                                font-weight: $weight-500;
                                margin: 0px;
                                line-height: normal;
                            }
                            p {
                                font-size: $size-11;
                                font-weight: $weight-400;
                                color: $color-black-100;
                                margin: 0px;
                                line-height: normal;
                            }
                            small {
                                font-size: $size-11;
                                font-weight: $weight-400;
                                color: $color-black-300;
                                margin: 0px;
                            }
                        }
                    }
                }
            }
        }
        .default-post-card-activity {
            margin: 6px 16px 6px 16px;
            border-bottom: 1px solid $color-border;
            .post-activity-counters {
                padding-bottom: 6px;
                b {
                    font-size: $size-13;
                    font-weight: $weight-500;
                    color: $color-black-100;
                }
                small {
                    font-size: $size-12;
                    font-weight: $weight-400;
                    color: $color-black-300;
                }
                a {
                    font-size: $size-12;
                    font-weight: $weight-400;
                    color: $color-black-300;
                    text-decoration: none;
                    outline: none;
                    &:hover {
                        text-decoration: underline;
                        color: $color-primary;
                    }
                }
            }
        }
        .default-post-card-actions {
            .post-activity-buttons {
                padding: 4px 16px 6px 16px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                .mat-button {
                    font-size: $size-13;
                    font-weight: $weight-500;
                    color: $color-black-100;
                    .mat-icon {
                        width: unset;
                        height: unset;
                        line-height: unset;
                        font-size: 16px;
                        margin-right: 2px;
                        color: $color-black-100;
                    }
                }
                .liked {
                    .mat-icon {
                        color: #e95353;
                    }
                }
            }
        }
        .default-post-card-inputs {
            .comment-input {
                padding: 4px 16px 6px 16px;
                display: flex;
                justify-content: flex-start;
                align-items: flex-start;
                .comment-creator {
                    margin-right: 10px;
                    .profile-photo-image {
                        width: 40px;
                        height: 40px;
                        overflow: hidden;
                        border-radius: 50%;
                        background-color: $color-white;
                        img {
                            height: 100%;
                            object-fit: contain;
                        }
                    }
                    .profile-photo-div {
                        background-color: rgba($color: $color-primary, $alpha: 1);
                        width: 40px;
                        height: 40px;
                        border-radius: 50%;
                        overflow: hidden;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        .mat-subheading-1 {
                            margin: auto 0 !important;
                            font-weight: $weight-500;
                            color: $color-white-100;
                        }
                    }
                }
                .comment-textarea {
                    flex-grow: 1;
                    .mat-flat-button {
                        border-radius: 4px;
                        font-size: $size-12;
                        font-weight: $weight-400;
                        padding: 4px 8px 4px 8px;
                        line-height: unset;
                        margin-top: 6px;
                    }
                    .comment-attachment-preview {
                        .audio-container {
                            margin: 0px 0px 0px 0px;
                        }
                        .doc-container {
                            margin: 0px 0px 0px 0px;
                        }
                    }
                }
                .comment-attachment {
                    margin-left: 10px;
                    text-align: right;
                    .mat-icon-button {
                        width: 40px;
                        height: 40px;
                        line-height: unset;
                        background-color: $color-secondary;
                        vertical-align: middle;
                        .mat-icon {
                            width: unset;
                            height: unset;
                            line-height: unset;
                            font-size: 20px;
                            vertical-align: middle;
                        }
                    }
                }
            }
        }
        .default-post-card-comments {
            margin: 10px 16px 0px 16px;
            .post-comments {
                display: flex;
                justify-content: flex-start;
                align-items: flex-start;
                margin-bottom: 16px;
                min-width: 0;
                .comment-creator {
                    margin-right: 10px;
                    .profile-photo-image {
                        width: 40px;
                        height: 40px;
                        overflow: hidden;
                        border-radius: 50%;
                        background-color: $color-white;
                        img {
                            height: 100%;
                            object-fit: contain;
                        }
                    }
                    .profile-photo-div {
                        background-color: rgba($color: $color-primary, $alpha: 1);
                        width: 40px;
                        height: 40px;
                        border-radius: 50%;
                        overflow: hidden;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        .mat-subheading-1 {
                            margin: auto 0 !important;
                            font-weight: $weight-500;
                            color: $color-white-100;
                        }
                    }
                }
                .comment-contents {
                    flex-grow: 1;
                    min-width: 0;
                    .comment-bubble {
                        background-color: $color-secondary;
                        border-radius: 0px 10px 10px 10px;
                        padding: 4px 10px 4px 10px;
                        .comment-metadata {
                            display: flex;
                            justify-content: space-between;
                            align-items: flex-start;
                            min-width: 0;
                            .creator-metadata {
                                min-width: 0;
                                span {
                                    font-size: $size-13;
                                    font-weight: $weight-500;
                                    margin: 0px;
                                    cursor: pointer;
                                    outline: none;
                                    max-width: 300px;
                                    white-space: nowrap;
                                    text-overflow: ellipsis;
                                    display: block;
                                    overflow: hidden;
                                    line-height: normal;
                                    &:hover {
                                        text-decoration: underline;
                                        color: $color-primary;
                                    }
                                }
                                small {
                                    font-size: $size-11;
                                    font-weight: $weight-400;
                                    color: $color-black-300;
                                    line-height: normal;
                                    margin: -2px 0px 0px 0px;
                                    max-width: 300px;
                                    white-space: nowrap;
                                    text-overflow: ellipsis;
                                    display: block;
                                    overflow: hidden;
                                }
                            }
                            .comment-action {
                                display: flex;
                                align-items: center;
                                justify-content: flex-end;
                                margin-left: 10px;
                                small {
                                    font-size: $size-11;
                                    font-weight: $weight-400;
                                    color: $color-black-300;
                                    margin: 0px;
                                    cursor: pointer;
                                }
                                .mat-icon-button {
                                    width: 30px;
                                    height: 30px;
                                    line-height: unset;
                                    margin-left: 0px;
                                    .mat-icon {
                                        width: unset;
                                        height: unset;
                                        line-height: unset;
                                    }
                                    &:hover {
                                        background-color: $color-secondary;
                                    }
                                }
                            }
                        }
                        .comment-data {
                            padding: 4px 0px 0px 0px;
                            .comment-text {
                                position: relative;
                                span {
                                    font-size: $size-13;
                                    font-weight: $weight-400;
                                    color: $color-black-100;
                                    cursor: default;
                                    white-space: pre-wrap;
                                    display: block;
                                    a {
                                        color: $color-primary;
                                        font-size: $size-13;
                                        font-weight: $weight-500;
                                        cursor: pointer;
                                        outline: none;
                                        text-decoration: none;
                                        &:hover {
                                            text-decoration: underline;
                                        }
                                    }
                                    b {
                                        font-weight: $weight-600;
                                    }
                                    .progress {
                                        background-color: $color-success;
                                        display: inline-block;
                                        padding: 0px 10px 0px 10px;
                                        border-radius: 4px;
                                        font-size: $size-13;
                                        font-weight: $weight-600;
                                        color: $color-white-100;
                                        margin-left: 6px;
                                    }
                                }
                                .see-more {
                                    position: absolute;
                                    bottom: 2px;
                                    right: 0px;
                                    margin: 0px 0px 0px 0px;
                                    background-color: $color-secondary;
                                    color: $color-black-300;
                                    font-size: $size-13;
                                    font-weight: $weight-400;
                                    cursor: pointer;
                                    outline: none;
                                    text-decoration: none;
                                    padding: 0px 0px 0px 8px;
                                    &:hover {
                                        text-decoration: underline;
                                    }
                                }
                                .mat-flat-button {
                                    border-radius: 4px;
                                    padding: 4px 8px 4px 8px;
                                    line-height: unset;
                                    margin: 8px 0px 6px 0px;
                                    cursor: pointer;
                                    span {
                                        color: $color-white-100;
                                        font-size: $size-12;
                                        font-weight: $weight-500;
                                        cursor: pointer;
                                    }
                                }
                            }
                            .comment-edit-textarea {
                                flex-grow: 1;
                                .mat-flat-button {
                                    border-radius: 4px;
                                    font-size: $size-12;
                                    font-weight: $weight-400;
                                    padding: 4px 8px 4px 8px;
                                    line-height: unset;
                                    margin-top: 6px;
                                }
                                .mat-stroked-button {
                                    border-radius: 4px;
                                    font-size: $size-12;
                                    font-weight: $weight-500;
                                    padding: 4px 8px 4px 8px;
                                    line-height: unset;
                                    margin-top: 6px;
                                }
                            }
                            .comment-content-hide {
                                max-height: 62px;
                                overflow: hidden;
                            }
                            .comment-attached-files {
                                padding: 4px 0px 4px 0px;
                            }
                        }
                    }
                    .comment-activity-counters {
                        font-size: $size-13;
                        font-weight: $weight-400;
                        color: $color-black-300;
                        margin-top: 4px;
                        .mat-button {
                            border-radius: 4px;
                            font-size: $size-12;
                            font-weight: $weight-500;
                            padding: 2px 1px 2px 1px;
                            line-height: unset;
                            color: $color-black-100;
                        }
                        b {
                            font-size: $size-13;
                            font-weight: $weight-500;
                            color: $color-black-100;
                        }
                        small {
                            font-size: $size-12;
                            font-weight: $weight-400;
                            color: $color-black-300;
                        }
                        a {
                            font-size: $size-12;
                            font-weight: $weight-400;
                            color: $color-black-300;
                            text-decoration: none;
                            outline: none;
                            &:hover {
                                text-decoration: underline;
                                color: $color-primary;
                            }
                        }
                    }
                    .reply-comments {
                        margin-top: 4px;
                        display: flex;
                        justify-content: flex-start;
                        align-items: flex-start;
                        min-width: 0;
                        .reply-creator {
                            margin-right: 10px;
                            .profile-photo-image {
                                width: 40px;
                                height: 40px;
                                overflow: hidden;
                                border-radius: 50%;
                                background-color: $color-white;
                                img {
                                    height: 100%;
                                    object-fit: contain;
                                }
                            }
                            .profile-photo-div {
                                background-color: rgba($color: $color-primary, $alpha: 1);
                                width: 40px;
                                height: 40px;
                                border-radius: 50%;
                                overflow: hidden;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                .mat-subheading-1 {
                                    margin: auto 0 !important;
                                    font-weight: $weight-500;
                                    color: $color-white-100;
                                }
                            }
                        }
                        .reply-contents {
                            flex-grow: 1;
                            min-width: 0;
                            .reply-bubble {
                                background-color: $color-secondary;
                                border-radius: 0px 10px 10px 10px;
                                padding: 4px 10px 4px 10px;
                                .reply-metadata {
                                    display: flex;
                                    justify-content: space-between;
                                    align-items: flex-start;
                                    min-width: 0;
                                    .creator-metadata {
                                        min-width: 0;
                                        span {
                                            font-size: $size-13;
                                            font-weight: $weight-500;
                                            margin: 0px;
                                            cursor: pointer;
                                            outline: none;
                                            max-width: 240px;
                                            white-space: nowrap;
                                            text-overflow: ellipsis;
                                            display: block;
                                            overflow: hidden;
                                            line-height: normal;
                                            &:hover {
                                                text-decoration: underline;
                                                color: $color-primary;
                                            }
                                        }
                                        small {
                                            font-size: $size-11;
                                            font-weight: $weight-400;
                                            color: $color-black-300;
                                            line-height: normal;
                                            margin: -2px 0px 0px 0px;
                                            max-width: 240px;
                                            white-space: nowrap;
                                            text-overflow: ellipsis;
                                            display: block;
                                            overflow: hidden;
                                        }
                                    }
                                    .reply-action {
                                        display: flex;
                                        align-items: center;
                                        justify-content: flex-end;
                                        margin-left: 10px;
                                        small {
                                            font-size: $size-11;
                                            font-weight: $weight-400;
                                            color: $color-black-300;
                                            margin: 0px;
                                            cursor: pointer;
                                        }
                                        .mat-icon-button {
                                            width: 30px;
                                            height: 30px;
                                            line-height: unset;
                                            margin-left: 0px;
                                            .mat-icon {
                                                width: unset;
                                                height: unset;
                                                line-height: unset;
                                            }
                                            &:hover {
                                                background-color: $color-secondary;
                                            }
                                        }
                                    }
                                }
                                .reply-data {
                                    padding: 4px 0px 0px 0px;
                                    .reply-text {
                                        position: relative;
                                        span {
                                            font-size: $size-13;
                                            font-weight: $weight-400;
                                            color: $color-black-100;
                                            cursor: default;
                                            white-space: pre-wrap;
                                            display: block;
                                            a {
                                                color: $color-primary;
                                                font-size: $size-13;
                                                font-weight: $weight-500;
                                                cursor: pointer;
                                                outline: none;
                                                text-decoration: none;
                                                &:hover {
                                                    text-decoration: underline;
                                                }
                                            }
                                        }
                                        .see-more {
                                            position: absolute;
                                            bottom: 2px;
                                            right: 0px;
                                            margin: 0px 0px 0px 0px;
                                            background-color: $color-secondary;
                                            color: $color-black-300;
                                            font-size: $size-13;
                                            font-weight: $weight-400;
                                            cursor: pointer;
                                            outline: none;
                                            text-decoration: none;
                                            padding: 0px 0px 0px 8px;
                                            &:hover {
                                                text-decoration: underline;
                                            }
                                        }
                                    }
                                    .reply-edit-textarea {
                                        flex-grow: 1;
                                        .mat-flat-button {
                                            border-radius: 4px;
                                            font-size: $size-12;
                                            font-weight: $weight-400;
                                            padding: 4px 8px 4px 8px;
                                            line-height: unset;
                                            margin-top: 6px;
                                        }
                                        .mat-stroked-button {
                                            border-radius: 4px;
                                            font-size: $size-12;
                                            font-weight: $weight-500;
                                            padding: 4px 8px 4px 8px;
                                            line-height: unset;
                                            margin-top: 6px;
                                        }
                                    }
                                    .reply-content-hide {
                                        max-height: 62px;
                                        overflow: hidden;
                                    }
                                    .reply-attached-files {
                                        padding: 4px 0px 4px 0px;
                                    }
                                }
                            }
                            .reply-activity-counters {
                                font-size: $size-13;
                                font-weight: $weight-400;
                                color: $color-black-300;
                                margin-top: 4px;
                                .mat-button {
                                    border-radius: 4px;
                                    font-size: $size-12;
                                    font-weight: $weight-500;
                                    padding: 2px 1px 2px 1px;
                                    line-height: unset;
                                    color: $color-black-100;
                                }
                                b {
                                    font-size: $size-13;
                                    font-weight: $weight-500;
                                    color: $color-black-100;
                                }
                                small {
                                    font-size: $size-12;
                                    font-weight: $weight-400;
                                    color: $color-black-300;
                                }
                                a {
                                    font-size: $size-12;
                                    font-weight: $weight-400;
                                    color: $color-black-300;
                                    text-decoration: none;
                                    outline: none;
                                    &:hover {
                                        text-decoration: underline;
                                        color: $color-primary;
                                    }
                                }
                            }
                        }
                    }
                    .load-more-replies {
                        .mat-button {
                            border-radius: 4px;
                            font-size: $size-12;
                            font-weight: $weight-500;
                            padding: 2px 10px 2px 10px;
                            line-height: unset;
                            color: $color-black-100;
                        }
                    }
                    .reply-input {
                        margin-top: 10px;
                        display: flex;
                        justify-content: flex-start;
                        align-items: flex-start;
                        .reply-creator {
                            margin-right: 10px;
                            .profile-photo-image {
                                width: 40px;
                                height: 40px;
                                overflow: hidden;
                                border-radius: 50%;
                                background-color: $color-white;
                                img {
                                    height: 100%;
                                    object-fit: contain;
                                }
                            }
                            .profile-photo-div {
                                background-color: rgba($color: $color-primary, $alpha: 1);
                                width: 40px;
                                height: 40px;
                                border-radius: 50%;
                                overflow: hidden;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                .mat-subheading-1 {
                                    margin: auto 0 !important;
                                    font-weight: $weight-500;
                                    color: $color-white-100;
                                }
                            }
                        }
                        .reply-textarea {
                            flex-grow: 1;
                            .mat-flat-button {
                                border-radius: 4px;
                                font-size: $size-12;
                                font-weight: $weight-400;
                                padding: 4px 8px 4px 8px;
                                line-height: unset;
                                margin-top: 6px;
                            }
                            .reply-attachment-preview {
                                .image-container {
                                    height: 130px;
                                }
                                .audio-container {
                                    margin: 0px 0px 6px 0px;
                                }
                                .doc-container {
                                    margin: 0px 0px 0px 0px;
                                    .doc-viewer {
                                        border: none;
                                        border-radius: 4px;
                                    }
                                }
                            }
                        }
                        .reply-attachment {
                            margin-left: 10px;
                            text-align: right;
                            .mat-icon-button {
                                width: 40px;
                                height: 40px;
                                line-height: unset;
                                background-color: $color-secondary;
                                vertical-align: middle;
                                .mat-icon {
                                    width: unset;
                                    height: unset;
                                    line-height: unset;
                                    font-size: 20px;
                                    vertical-align: middle;
                                }
                            }
                        }
                    }
                    .image-container {
                        display: flex;
                        height: 160px;
                        overflow: hidden;
                        img {
                            max-width: 100%;
                            max-height: 100%;
                            object-fit: cover;
                        }
                    }
                    .video-container {
                        display: flex;
                        margin: 0px 0px 10px 0px;
                        height: 200px;
                    }
                    .audio-container {
                        display: flex;
                        margin: 0px 0px 10px 0px;
                        .audio-player {
                            width: 100%;
                        }
                    }
                    .doc-container {
                        .doc-viewer {
                            padding: 6px 10px 6px 10px;
                            border-radius: 10px;
                            background-color: $color-secondary;
                            display: flex;
                            align-items: center;
                            border: 1px solid $color-accent;
                            .doc-icon {
                                margin-right: 10px;
                                .mat-icon {
                                    width: unset;
                                    height: unset;
                                    line-height: unset;
                                    vertical-align: middle;
                                    font-size: 30px;
                                    color: $color-black-100;
                                }
                                .pdf {
                                    color: #e64f4f;
                                }
                                .excel {
                                    color: #09b47b;
                                }
                                .doc {
                                    color: #0959b4;
                                }
                            }
                            .doc-metadata {
                                flex-grow: 1;
                                h3 {
                                    font-size: $size-14;
                                    font-weight: $weight-500;
                                    margin: 0px;
                                    line-height: normal;
                                    display: inline-block;
                                }
                                b {
                                    font-size: $size-13;
                                    font-weight: $weight-500;
                                    color: $color-black-100;
                                }
                                span {
                                    font-size: $size-12;
                                    font-weight: $weight-400;
                                    color: $color-black-300;
                                    line-height: normal;
                                    margin: 0px;
                                }
                                small {
                                    font-size: $size-11;
                                    font-weight: $weight-400;
                                    color: $color-black-300;
                                    line-height: normal;
                                    margin: 0px;
                                }
                            }
                            .doc-action {
                                margin-left: 10px;
                                text-align: right;
                                .mat-icon-button {
                                    border: 1px solid $color-accent;
                                    width: 30px;
                                    height: 30px;
                                    line-height: unset;
                                    background-color: $color-secondary;
                                    vertical-align: middle;
                                    .mat-icon {
                                        width: unset;
                                        height: unset;
                                        line-height: unset;
                                        font-size: 18px;
                                        vertical-align: middle;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        .default-post-card-footer {
            padding: 4px 16px 10px 16px;
            .load-more-comments {
                .mat-button {
                    border-radius: 4px;
                    font-size: $size-12;
                    font-weight: $weight-500;
                    padding: 2px 10px 2px 10px;
                    line-height: unset;
                    color: $color-black-100;
                }
            }
        }
    }
}

.grid-view {
    position: relative;
    .grid-list-action {
        position: absolute;
        top: 0px;
        right: 0px;
        z-index: 2;
        margin: 10px 10px 0px 0px;
        .mat-icon-button {
            width: 30px;
            height: 30px;
            line-height: unset;
            background-color: $color-accent;
            .mat-icon {
                width: unset;
                height: unset;
                line-height: unset;
                vertical-align: middle;
                font-size: 20px;
                color: $color-white;
            }
        }
    }
    .grids {
        display: flex;
        flex-wrap: wrap;
        position: relative;
        margin: 6px 0px 0px 0px;
        .grid-list {
            width: 50%;
            cursor: pointer;
            .grid-container {
                width: 100%;
                height: 100%;
                position: relative;
                .image-container {
                    width: 100%;
                    height: 100%;
                    position: relative;
                    display: flex;
                    justify-content: center;
                    img {
                        width: 100%;
                        height: 100%;
                        object-position: center;
                        object-fit: cover;
                        border: 2px solid $color-white;
                    }
                    .more-attachments {
                        position: absolute;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        top: 0px;
                        left: 0px;
                        right: 0px;
                        bottom: 0px;
                        text-align: center;
                        height: 100%;
                        margin-top: 2px;
                        background-color: rgba($color: $color-black, $alpha: 0.3);
                        h2 {
                            color: $color-white-100;
                        }
                    }
                }
                .video-container {
                    .video-player {
                        width: 100%;
                        max-height: 400px;
                    }
                }
                .audio-container {
                    margin: 0px 16px 0px 16px;
                    .audio-player {
                        width: 100%;
                    }
                }
                .doc-container {
                    margin: 0px 16px 10px 16px;
                    .doc-viewer {
                        padding: 6px 10px 6px 10px;
                        border-radius: 4px;
                        background-color: $color-secondary;
                        display: flex;
                        justify-content: flex-start;
                        align-items: center;
                        .doc-icon {
                            margin-right: 10px;
                            .mat-icon {
                                width: unset;
                                height: unset;
                                line-height: unset;
                                vertical-align: middle;
                                font-size: 36px;
                                color: $color-black-100;
                            }
                            .pdf {
                                color: #e64f4f;
                            }
                            .excel {
                                color: #09b47b;
                            }
                            .doc {
                                color: #0959b4;
                            }
                        }
                        .doc-metadata {
                            flex-grow: 1;
                            h3 {
                                font-size: $size-14;
                                font-weight: $weight-500;
                                margin: 0px;
                                line-height: normal;
                                display: block;
                            }
                            span {
                                font-size: $size-12;
                                font-weight: $weight-400;
                                color: $color-black-300;
                                margin: 0px;
                                line-height: normal;
                                display: block;
                            }
                            small {
                                font-size: $size-11;
                                font-weight: $weight-400;
                                color: $color-black-300;
                                margin: 0px;
                                line-height: normal;
                                display: block;
                            }
                        }
                        .doc-action {
                            margin-left: 10px;
                            text-align: right;
                            .mat-icon-button {
                                border: 1px solid $color-accent;
                                width: 36px;
                                height: 36px;
                                line-height: unset;
                                background-color: $color-secondary;
                                vertical-align: middle;
                                .mat-icon {
                                    width: unset;
                                    height: unset;
                                    line-height: unset;
                                    font-size: 20px;
                                    vertical-align: middle;
                                }
                            }
                        }
                    }
                }
            }
        }
        .items-1 {
            width: 100%;
            img {
                border: none !important;
            }
        }
        .items-2 {
            width: 50%;
        }
        .items-3 {
            &:nth-child(1) {
                width: 100%;
            }
        }
        .items-4 {
            width: 33.3%;
            &:nth-child(1) {
                width: 100%;
            }
        }
        .items-5 {
            width: 33.3%;
            &:nth-child(-n + 2) {
                width: 50%;
            }
        }
    }
}

.verticle-menubar-card {
    border-radius: 10px;
    background-color: $color-white;
    border: 1px solid $color-border;
    border-bottom: 2px solid $color-border;
    margin-bottom: 16px;
    padding: 10px 10px 10px 10px;
    overflow: hidden;
    .verticle-menubar {
        .section-title {
            padding: 0px 10px 0px 10px;
            h3 {
                font-size: $size-13;
                font-weight: $weight-500;
                color: $color-black-300;
                text-transform: uppercase;
                margin: 0px;
            }
        }
        ul {
            padding: 0px;
            margin: 0px;
            list-style-type: none;
            .list-item {
                .item {
                    text-decoration: none;
                    outline: none;
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    padding: 8px 10px 8px 10px;
                    border-radius: 10px;
                    .circle-icon {
                        width: 30px;
                        height: 30px;
                        background-color: $color-secondary;
                        border-radius: 50%;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        margin-right: 10px;
                        .mat-icon {
                            width: unset;
                            height: unset;
                            line-height: unset;
                            color: $color-accent;
                            font-size: 20px;
                        }
                    }
                    span {
                        flex-grow: 9;
                        font-size: $size-13;
                        font-weight: $weight-500;
                        color: $color-black-100;
                        margin: 0px;
                    }
                    .counter {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        width: 20px;
                        height: 20px;
                        background-color: $color-accent;
                        border-radius: 50%;
                        h4 {
                            font-size: $size-11;
                            font-weight: $weight-500;
                            color: $color-white-100;
                            margin: 0px;
                        }
                    }
                    &:hover {
                        background-color: $color-secondary;
                    }
                }
                .active {
                    background-color: $color-secondary;
                    .circle-icon {
                        background-color: rgba($color: $color-primary, $alpha: 0.1);
                        .mat-icon {
                            color: $color-primary !important;
                        }
                    }
                    span {
                        color: $color-primary;
                    }
                }
            }
        }
    }
}

.horizontal-menubar-container {
    background-color: $color-white;
    border-bottom: 1px solid $color-border;
    .horizontal-menubar-card {
        position: fixed;
        top: 56px;
        left: 0px;
        right: 0px;
        z-index: 9999;
        height: 50px;
        display: flex;
        align-items: center;
        overflow-y: hidden;
        overflow-x: auto;
        white-space: nowrap;
        .horizontal-menubar {
            ul {
                padding: 0px;
                margin: 0px;
                list-style-type: none;
                display: flex;
                align-items: center;
                .list-item {
                    .item {
                        cursor: pointer;
                        text-decoration: none;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        padding: 6px 10px 6px 10px;
                        margin-right: 6px;
                        border-radius: 10px;
                        position: relative;
                        .mat-icon {
                            width: unset;
                            height: unset;
                            line-height: unset;
                            font-size: 18px;
                            color: $color-black-300;
                            vertical-align: middle;
                        }
                        span {
                            font-size: $size-12;
                            font-weight: $weight-500;
                            color: $color-black-100;
                            margin: 0px;
                        }
                        &:hover {
                            background-color: $color-secondary;
                        }
                        &::after {
                            content: "";
                            position: absolute;
                            width: 0px;
                            height: 2px;
                            left: 50%;
                            bottom: -10px;
                            background-color: $color-primary;
                            @include transition(all ease-in-out 0.2s);
                        }
                    }
                    .active {
                        background-color: $color-secondary;
                        span {
                            color: $color-primary;
                        }
                        .mat-icon {
                            color: $color-primary;
                        }
                        &::after {
                            margin: 0 auto;
                            width: 90%;
                            left: 0;
                            right: 0;
                        }
                    }
                }
            }
        }
    }
}

.dropdown-menubar {
    padding: 6px 0px 6px 0px !important;
    margin-top: 0px;
    border-radius: 10px !important;
    min-height: unset !important;
    min-width: 180px !important;
    @include box-shadow(0px 2px 5px 0px rgba(0, 0, 0, 0.25));
    .mat-menu-content {
        padding: 0px !important;
    }
    .mat-menu-item {
        line-height: unset;
        height: unset;
        padding: 10px 16px 10px 16px !important;
        font-size: $size-13 !important;
        font-weight: $weight-500;
        color: $color-black-100;
        &:hover {
            background-color: $color-secondary !important;
        }
    }
    .active {
        color: $color-primary;
    }
}

.dropdown-small-menubar {
    padding: 6px 0px 6px 0px !important;
    margin-top: 0px;
    border-radius: 10px !important;
    min-height: unset !important;
    min-width: 180px !important;
    @include box-shadow(0px 2px 5px 0px rgba(0, 0, 0, 0.25));
    .mat-menu-content {
        padding: 0px !important;
    }
    .mat-menu-item {
        line-height: unset;
        height: unset;
        display: flex;
        align-items: center;
        padding: 8px 16px 8px 16px !important;
        font-size: $size-13 !important;
        font-weight: $weight-500 !important;
        color: $color-black-100 !important;
        white-space: normal !important;
        .mat-icon {
            width: unset;
            height: unset;
            line-height: unset;
            margin-right: 8px;
        }
        span {
            display: block;
            font-size: $size-13 !important;
            font-weight: $weight-500 !important;
            color: $color-black-100 !important;
            line-height: normal !important;
            margin-bottom: 0px;
        }
        small {
            font-size: $size-11 !important;
            font-weight: $weight-400 !important;
            color: $color-black-300 !important;
            line-height: normal !important;
            margin: 0px;
            display: block;
        }
        img {
            width: 30px;
            object-fit: cover;
            vertical-align: middle;
            margin-right: 8px;
        }
        &:hover {
            background-color: $color-secondary !important;
        }
    }
    .active {
        color: $color-primary;
    }
}

.common-table {
    .mat-table {
        width: 100%;
        border: 1px solid $color-border;
        border-bottom: 0px;
        border-radius: 10px 10px 0px 0px;
        overflow: hidden;
        .mat-header-row {
            min-height: 0px;
            background-color: $color-white;
            border-radius: 10px 10px 0px 0px;
            .mat-header-cell {
                padding: 16px 16px 16px 16px;
                font-weight: $weight-600;
                font-size: $size-14;
                color: $color-black-100;
                .mat-checkbox {
                    .mat-checkbox-frame {
                        border-color: $color-black-300 !important;
                    }
                }
            }
        }
        .mat-row {
            min-height: 0px;
            background-color: $color-white;
            .mat-cell {
                padding: 10px 16px 10px 16px;
                h4 {
                    font-size: $size-14;
                    font-weight: $weight-400;
                    color: $color-black-100;
                    margin: 0px !important;
                }
                span {
                    font-size: $size-13;
                    color: $color-black-100;
                }
                a {
                    color: $color-black-100;
                    outline: none;
                    text-decoration: none;
                    &:hover {
                        text-decoration: underline;
                    }
                }
                .mat-checkbox {
                    .mat-checkbox-frame {
                        border-color: rgba($color: $color-accent, $alpha: 0.2) !important;
                    }
                }
            }
            .action-cell {
                .action-button {
                    width: 36px !important;
                    height: 36px !important;
                    line-height: unset !important;
                    vertical-align: middle !important;
                    .mat-icon {
                        font-size: 21px;
                        color: $color-accent;
                    }
                    &:hover {
                        background-color: $color-secondary;
                        .mat-icon {
                            color: $color-accent;
                        }
                    }
                }
            }
            &:hover {
                background-color: $color-secondary;
                .mat-cell {
                    h4 {
                        color: $color-black-100;
                    }
                    .mat-checkbox {
                        .mat-checkbox-frame {
                            border-color: $color-black-300 !important;
                        }
                    }
                }
                .action-cell {
                    .mat-icon {
                        color: $color-accent !important;
                    }
                }
            }
            .action-cell {
                padding: 0px 16px 0px 16px;
                .action-button {
                    .mat-icon {
                        color: $color-accent;
                    }
                    &:hover {
                        background-color: rgba($color: $color-primary, $alpha: 0.2);
                        .mat-icon {
                            color: $color-white;
                        }
                    }
                }
            }
            .label-cell {
                padding: 0px 16px 0px 16px;
            }
        }
        .mat-footer-row {
            .mat-footer-cell {
                font-weight: $weight-500;
                padding: 16px 16px 16px 16px;
                font-size: $size-13;
                color: $color-black-100;
            }
        }
    }
    .mat-paginator {
        background-color: $color-white;
        border: 1px solid $color-border;
        border-top: 0px;
        border-bottom: 2px solid $color-border;
        width: 100%;
        border-radius: 0px 0px 10px 10px;
        .mat-paginator-outer-container {
            .mat-paginator-container {
                .mat-paginator-page-size {
                    .mat-paginator-page-size-label {
                        font-weight: $weight-400;
                        font-size: $size-13;
                        color: $color-black-100;
                    }
                    .mat-paginator-page-size-select {
                        font-weight: $weight-400;
                        font-size: $size-13;
                        color: $color-black-100;
                    }
                }
                .mat-paginator-range-actions {
                    .mat-paginator-range-label {
                        font-weight: $weight-400;
                        font-size: $size-13;
                        color: $color-black-100;
                    }
                    .mat-icon-button {
                        .mat-paginator-icon {
                            color: $color-border;
                        }
                    }
                    .mat-icon-button:not([disabled]) {
                        .mat-paginator-icon {
                            color: $color-accent;
                        }
                        &:hover {
                            background-color: $color-secondary !important;
                        }
                    }
                }
            }
        }
    }
}
.mat-elevation-z8 {
    box-shadow: none;
}

.custom-table {
    table {
        width: 100%;
        border-spacing: 0px;
        thead {
            tr {
                background-color: $color-white;
                text-align: left;
                th {
                    font-weight: $weight-600;
                    font-size: $size-14;
                    color: $color-black-100;
                    border-radius: 4px 4px 0px 0px;
                    padding: 16px 16px 16px 16px;
                    border-bottom: 1px solid $color-border;
                }
            }
        }
        tbody {
            tr {
                background-color: $color-white;
                td {
                    font-weight: $weight-400;
                    padding: 12px 16px 12px 16px;
                    font-size: $size-13;
                    color: $color-black-100;
                    border-bottom: 1px solid $color-border;
                    .mat-icon {
                        width: unset;
                        height: unset;
                        line-height: unset;
                        color: $color-accent;
                        vertical-align: middle;
                        font-size: $size-18;
                    }
                }
                &:hover {
                    background-color: $color-secondary;
                }
                .divider {
                    border-bottom: 1px solid $color-accent !important;
                }
            }
        }
    }
}

.default-table {
    width: 100%;
    border-spacing: unset;
    thead {
        tr {
            th {
                text-align: left;
                font-weight: $weight-600;
                font-size: $size-14;
                color: $color-black-100;
                padding: 6px 0px 6px 0px;
                border-bottom: 1px solid $color-border;
            }
        }
    }
    tbody {
        tr {
            td {
                padding: 8px 0px 8px 0px;
                font-weight: $weight-400;
                font-size: $size-13;
                color: $color-black-100;
                text-align: left;
                b {
                    font-weight: $weight-500;
                }
                span {
                    font-weight: $weight-400;
                    font-size: $size-13;
                    color: $color-black-100;
                }
            }
        }
    }
}

.round-toggle-group {
    border-radius: 30px !important;
    line-height: unset;
    height: unset;
    border-color: $color-primary !important;
    .mat-button-toggle-label-content {
        display: flex;
        align-items: center;
        padding: 18px 16px 18px 16px !important;
        line-height: 0px !important;
        font-weight: $weight-500;
        font-size: $size-13;
        color: $color-black-100;
        .mat-icon {
            width: unset;
            height: unset;
            line-height: unset;
            font-size: 20px;
            color: $color-accent;
            margin-right: 6px;
        }
    }
    .mat-button-toggle-checked {
        background-color: $color-primary !important;
        .mat-button-toggle-label-content {
            color: $color-white !important;
            .mat-icon {
                color: $color-white !important;
            }
        }
    }
}

.mat-dialog-container {
    border-radius: 10px !important;
    padding: 0px 0px 0px 0px !important;
}
.modal {
    padding: 0px;
    .modal-header {
        border-bottom: 1px solid $color-border;
        margin: 24px 24px 0px 24px;
        .modal-title {
            display: flex;
            justify-content: center;
            align-items: center;
            h3 {
                font-weight: $weight-500;
                margin: 0px 0px 0px 0px;
                vertical-align: bottom;
            }
            .div-spacer {
                flex: 1 1 auto;
            }
            .mat-icon-button {
                width: 34px;
                height: 34px;
                line-height: unset;
                .mat-icon {
                    color: $color-accent;
                    font-size: 20px;
                    font-weight: $weight-500;
                }
                &:hover {
                    background-color: $color-secondary;
                }
            }
        }
        .linkedin-header {
            margin-bottom: 6px;
            .linkedin-logo {
                width: 100px;
                object-fit: cover;
            }
        }
    }
    .modal-body {
        padding: 10px 22px 10px 24px;
        margin-right: 2px;
        .modal-message {
            margin-top: 16px;
            h3 {
                font-weight: $weight-500;
                color: $color-black-100;
            }
            h4 {
                font-weight: $weight-500;
                color: $color-black-100;
            }
        }
    }
    .modal-footer {
        text-align: right;
        padding: 4px 24px 24px 24px;
        button {
            margin: 0px 0px 0px 5px;
        }
        .linkedin-button {
            background-color: #dceff7;
            border: none;
            color: #0073b1;
            &:hover {
                background-color: #c6dee9;
            }
        }
    }
}

.modal-body-scroller {
    max-height: 500px;
    overflow-y: auto;
    overflow-x: hidden;
}
.modal-body-scroller::-webkit-scrollbar {
    width: 10px;
    position: absolute;
    top: 0px;
}
.modal-body-scroller::-webkit-scrollbar-track {
    background: $color-white;
}
.modal-body-scroller::-webkit-scrollbar-thumb {
    background: rgba($color: $color-accent, $alpha: 0.1);
    border-radius: 30px;
}
.modal-body-scroller::-webkit-scrollbar-thumb:hover {
    background: rgba($color: $color-accent, $alpha: 0.2);
}

.custom-loader {
    position: absolute;
    width: 100%;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    z-index: 9;
    background-color: rgba($color: $color-white, $alpha: 0.8);
    .loader-text {
        position: sticky;
        top: 80px;
        display: flex;
        width: 100%;
        padding-top: 20%;
        justify-content: center;
        align-items: center;
        h4 {
            font-weight: $weight-500;
            margin: 0px 0px 0px 10px;
        }
        .mat-spinner {
            circle {
                stroke: $color-accent;
            }
        }
    }
}

.custom-scroll-loader {
    .loader-text {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0px 0px 0px 0px;
        .mat-spinner {
            circle {
                stroke: $color-accent;
            }
        }
    }
}

.custom-details-loader {
    .loader-text {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0px 0px 0px 0px;
        .mat-spinner {
            circle {
                stroke: $color-accent;
            }
        }
    }
}

.loadmore-top {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: -10px;
    margin-bottom: -26px;
    z-index: 9999;
    .round-flat-button {
        @include box-shadow(0px 4px 10px 0px rgba(0, 0, 0, 0.25));
    }
}

.radio-list {
    .radio-header {
        padding: 10px 16px 10px 16px;
        background-color: $color-secondary;
        h3 {
            font-size: $size-14;
            font-weight: $weight-500;
            color: $color-black-100;
            margin: 0px;
        }
        small {
            font-size: $size-12;
            font-weight: $weight-400;
            color: $color-black-300;
            margin: 0px;
        }
    }
    .radio-container {
        .radio-group {
            display: flex;
            flex-direction: column;
            margin: 0px 0px 0px 0px;
            .radio-button {
                margin: 6px 8px 6px 8px;
                .mat-radio-label-content {
                    margin-left: 10px;
                    display: flex;
                    flex-direction: column;
                    width: 100%;
                    h4 {
                        font-size: $size-13;
                        font-weight: $weight-500;
                        color: $color-black-100;
                        margin: 0px;
                        line-height: unset;
                    }
                    span {
                        display: inline-block;
                        font-size: $size-13;
                        font-weight: $weight-400;
                        color: $color-black-300;
                    }
                }
                .mat-radio-label {
                    white-space: unset !important;
                }
            }
        }
    }
}

.check-list {
    .check-header {
        padding: 10px 16px 10px 16px;
        background-color: $color-secondary;
        h3 {
            font-size: $size-14;
            font-weight: $weight-500;
            color: $color-black-100;
            margin: 0px;
        }
    }
    .check-container {
        .mat-checkbox {
            margin: 10px 20px 10px 10px;
            h4 {
                font-size: $size-13;
                font-weight: $weight-500;
                color: $color-black-100;
                margin: 0px;
                line-height: unset;
            }
            span {
                display: inline-block;
                font-size: $size-13;
                font-weight: $weight-400;
                color: $color-black-300;
            }
            .mat-checkbox-layout {
                white-space: unset !important;
                .mat-checkbox-label {
                    margin-left: 10px;
                }
            }
        }
    }
}

.default-group-tabs {
    .mat-tab-header {
        .mat-tab-list {
            .mat-tab-label {
                height: unset;
                padding: 10px 16px 10px 16px;
                opacity: 1;
                min-width: unset;
                .mat-tab-label-content {
                    font-size: $size-13;
                    font-weight: $weight-400;
                    color: $color-black-100;
                }
            }
            .mat-tab-label-active {
                .mat-tab-label-content {
                    color: $color-primary;
                }
            }
        }
    }
}

.custom-pagination {
    background-color: $color-white;
    border: 1px solid $color-border;
    border-bottom: 2px solid $color-border;
    border-radius: 10px;
    overflow: hidden;
    .mat-paginator {
        width: 100%;
        .mat-paginator-outer-container {
            .mat-paginator-container {
                .mat-paginator-page-size {
                    .mat-paginator-page-size-label {
                        font-weight: $weight-400;
                        font-size: $size-13;
                        color: $color-black-100;
                    }
                    .mat-paginator-page-size-select {
                        font-weight: $weight-400;
                        font-size: $size-13;
                        color: $color-black-100;
                    }
                }
                .mat-paginator-range-actions {
                    .mat-paginator-range-label {
                        font-weight: $weight-400;
                        font-size: $size-13;
                        color: $color-black-100;
                    }
                    .mat-icon-button {
                        .mat-paginator-icon {
                            color: $color-border;
                        }
                    }
                    .mat-icon-button:not([disabled]) {
                        .mat-paginator-icon {
                            color: $color-accent;
                        }
                        &:hover {
                            background-color: $color-secondary !important;
                        }
                    }
                }
            }
        }
    }
}

.custom-toggle {
    border-radius: 30px !important;
    .mat-button-toggle {
        padding: 6px 0px 6px 0px !important;
        .mat-button-toggle-label-content {
            font-size: $size-13;
            font-weight: $weight-500;
            color: $color-primary;
            line-height: normal;
            .mat-icon {
                color: $color-primary;
                line-height: unset;
                width: unset;
                height: unset;
                vertical-align: middle;
            }
        }
    }
    .mat-button-toggle-checked {
        background-color: $color-primary !important;
        .mat-button-toggle-label-content {
            color: $color-white-100;
            .mat-icon {
                color: $color-white;
            }
        }
    }
}

.popover {
    background-color: $color-white;
    padding: 16px 16px 16px 16px;
    border-radius: 10px;
    max-width: 280px;
    @include box-shadow(0px 2px 5px 0px rgba(0, 0, 0, 0.25));
    .popover-body {
        padding: 0px 0px 0px 0px;
        .profile-actor {
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
            .profile-actor-photo {
                margin-right: 10px;
                .profile-photo-image {
                    width: 54px;
                    height: 54px;
                    overflow: hidden;
                    border-radius: 50%;
                    background-color: $color-white;
                    img {
                        height: 100%;
                        object-fit: contain;
                    }
                }
                .profile-photo-div {
                    background-color: rgba($color: $color-primary, $alpha: 1);
                    width: 54px;
                    height: 54px;
                    border-radius: 50%;
                    overflow: hidden;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    .mat-subheading-2 {
                        margin: auto 0 !important;
                        font-weight: $weight-500;
                        color: $color-white-100;
                    }
                }
            }
            .profile-actor-details {
                h4 {
                    font-size: $size-14;
                    font-weight: $weight-500;
                    margin: 0px;
                    display: block;
                    cursor: pointer;
                    outline: none;
                    line-height: normal;
                    &:hover {
                        color: $color-primary;
                    }
                }
                span {
                    display: block;
                    font-size: $size-12;
                    font-weight: $weight-400;
                    color: $color-black-300;
                    margin: 0px;
                    line-height: normal;
                }
                small {
                    display: block;
                    font-size: $size-11;
                    font-weight: $weight-400;
                    color: $color-black-300;
                    margin: 0px;
                    line-height: normal;
                }
                .action-buttons {
                    span {
                        font-size: $size-14;
                        font-weight: $weight-500;
                        color: $color-white;
                        padding: 6px 0px 6px 0px;
                    }
                }
            }
        }
    }
}

.attachments-preview-table {
    margin-bottom: 16px;
    .preview-list {
        border-radius: 4px;
        background-color: rgba($color: $color-primary, $alpha: 0.1);
        padding: 6px 6px 6px 6px;
        .preview-container {
            display: flex;
            justify-content: space-between;
            align-items: center;
            flex-direction: row;
            margin: 6px 6px 6px 6px;
            padding: 6px 6px 6px 6px;
            border: 1px dashed $color-primary;
            border-radius: 4px;
            .file-preview {
                flex: 0.2;
                span {
                    font-size: $size-13;
                    font-weight: $weight-400;
                    color: $color-black-100;
                    text-transform: uppercase;
                }
            }
            .file-metadata {
                flex: 0.7;
                span {
                    font-size: $size-13;
                    font-weight: $weight-400;
                    color: $color-black-100;
                }
            }
            .file-action {
                flex: 0.1;
                text-align: right;
                .mat-icon-button {
                    width: 22px;
                    height: 22px;
                    line-height: unset;
                    background-color: $color-accent;
                    margin-left: 6px;
                    .mat-icon {
                        width: unset;
                        height: unset;
                        line-height: unset;
                        font-size: 16px;
                        color: $color-white;
                    }
                }
            }
        }
    }
}

.contents {
    border-radius: 4px;
    border: 1px solid $color-black;
    padding: 16px 16px 16px 16px;
    .content {
        padding: 8px 0px 8px 0px;
        .content-title {
            h4 {
                font-weight: $weight-500;
                color: $color-black-100;
                margin: 0px;
            }
        }
        .content-description {
            p {
                font-size: $size-13;
                font-weight: $weight-400;
                color: $color-black-300;
            }
        }
        .content-attachments {
            .attachments-preview-table {
                .preview-list {
                    padding: 2px 2px 2px 2px;
                    .preview-container {
                        padding: 4px 8px 4px 8px;
                        .file-preview {
                            span {
                                font-size: $size-13;
                                font-weight: $weight-600;
                            }
                        }
                        .file-metadata {
                            span {
                                font-size: $size-11;
                                font-weight: $weight-400;
                                color: $color-black-100;
                            }
                        }
                        .file-action {
                            .mat-icon-button {
                                width: 20px;
                                height: 20px;
                                .mat-icon {
                                    font-size: 16px;
                                }
                            }
                        }
                    }
                }
            }
        }
        .content-answer {
            padding: 0px 0px 10px 0px;
            border-bottom: 1px solid $color-border;
            .mat-stroked-button {
                border-radius: 30px;
                font-size: $size-13;
                font-weight: $weight-600;
            }
            .answer {
                padding: 6px 10px 6px 10px;
                margin-bottom: 10px;
                border-radius: 4px;
                background-color: rgba($color: $color-theme, $alpha: 0.08);
                label {
                    font-size: $size-13;
                    font-weight: $weight-600;
                    color: $color-black-300;
                    border-bottom: 2px solid $color-black-300;
                }
                p {
                    font-size: $size-13;
                    font-weight: $weight-500;
                    color: $color-black-100;
                    line-height: 24px;
                    margin-top: 6px;
                    margin-bottom: 0px;
                    padding: 0px;
                }
            }
        }
        .sub-content {
            padding: 8px 0px 8px 0px;
            .sub-content-title {
                h4 {
                    font-weight: $weight-500;
                    color: $color-black-100;
                    margin: 0px;
                }
            }
            .sub-content-description {
                p {
                    font-size: $size-13;
                    font-weight: $weight-400;
                    color: $color-black-300;
                }
            }
            .sub-content-attachments {
                .attachments-preview-table {
                    .preview-list {
                        padding: 2px 2px 2px 2px;
                        .preview-container {
                            padding: 4px 8px 4px 8px;
                            .file-preview {
                                span {
                                    font-size: $size-13;
                                    font-weight: $weight-600;
                                }
                            }
                            .file-metadata {
                                span {
                                    font-size: $size-11;
                                    font-weight: $weight-400;
                                    color: $color-black-100;
                                }
                            }
                            .file-action {
                                .mat-icon-button {
                                    width: 20px;
                                    height: 20px;
                                    .mat-icon {
                                        font-size: 14px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
            .sub-content-answer {
                padding: 0px 0px 10px 0px;
                border-bottom: 1px solid $color-border;
                .mat-stroked-button {
                    border-radius: 30px;
                    font-size: $size-13;
                    font-weight: $weight-600;
                }
                .answer {
                    padding: 6px 10px 6px 10px;
                    margin-bottom: 10px;
                    border-radius: 4px;
                    background-color: rgba($color: $color-theme, $alpha: 0.08);
                    label {
                        font-size: $size-13;
                        font-weight: $weight-600;
                        color: $color-black-300;
                        border-bottom: 2px solid $color-black-300;
                    }
                    p {
                        font-size: $size-13;
                        font-weight: $weight-500;
                        color: $color-black-100;
                        line-height: 24px;
                        margin-top: 6px;
                        margin-bottom: 0px;
                        padding: 0px;
                    }
                }
            }
        }
    }
}

.angular-editor {
    .angular-editor-toolbar {
        border-radius: 4px;
        background-color: $color-secondary !important;
        border: 1px solid $color-border !important;
        margin-top: 16px;
        margin-bottom: 0px;
    }
    .angular-editor-wrapper {
        .angular-editor-textarea {
            border-radius: 4px;
            border: 1px solid $color-accent !important;
            padding: 16px 10px 16px 10px !important;
            font-weight: $weight-400;
            font-size: $size-13;
            color: $color-accent;
            b {
                font-weight: $weight-600;
            }
        }
        .angular-editor-placeholder {
            padding: 16px 10px 16px 10px !important;
            font-weight: $weight-400 !important;
            font-size: $size-13 !important;
        }
    }
    .angular-editor-button.active {
        background-color: $color-accent !important;
        color: $color-white !important;
        font-weight: normal !important;
    }
}

.custom-button-toggle {
    display: flex !important;
    flex-direction: row !important;
    flex-wrap: wrap;
    .mat-button-toggle {
        .mat-button-toggle-label-content {
            line-height: unset;
            font-weight: $weight-500 !important;
            font-size: $size-13;
        }
    }
    .mat-button-toggle-checked.color-default {
        background-color: $color-white !important;
        .mat-button-toggle-label-content {
            color: $color-primary !important;
        }
    }
}
