/* You can add global styles to this file, and also import other style files */
@import url("https://fonts.googleapis.com/css?family=Poppins:200,300,400,500,600,700,800,900&display=swap");

@import "~@angular/material/theming";
$custom-typography: mat-typography-config(
    $font-family: "Poppins",
);

$my-own-theme: (
    500: #e87630,
    contrast: (
        500: #ffffff,
    ),
);

$my-own-black: (
    500: #131216,
    contrast: (
        500: #ffffff,
    ),
);
$my-own-red: (
    500: #e83030,
    contrast: (
        500: #131216,
    ),
);

@include mat-core($custom-typography);
$my-app-primary: mat-palette($my-own-theme, 500);
$my-app-accent: mat-palette($my-own-black, 500);
$my-app-warn: mat-palette($my-own-red);
$my-app-theme: mat-light-theme($my-app-primary, $my-app-accent, $my-app-warn);
@include angular-material-theme($my-app-theme);

// Variables
$font-name: "Poppins", sans-serif;

$color-black: #000000;
$color-white: #ffffff;
$color-primary: #e87630;
$color-accent: #131216;
$color-theme: #74326f;
$color-secondary: #f7f6ff;
$color-warn: #e83030;
$color-border: #e0dbdb;

$color-black-100: #19181f;
$color-black-300: #6d6b72;
$color-white-100: #ffffff;
$color-white-300: #c9c1be;

$color-danger: #f44336;
$color-warning: #ffa200;
$color-success: #00db00;

$weight-200: 200;
$weight-300: 300;
$weight-400: 400;
$weight-500: 500;
$weight-600: 600;
$weight-700: 700;
$weight-800: 800;
$weight-900: 900;

$size-10: 10px;
$size-11: 11px;
$size-12: 12px;
$size-13: 13px;
$size-14: 14px;
$size-15: 15px;
$size-16: 16px;
$size-17: 17px;
$size-18: 18px;

@mixin transition($transitioneffects) {
    -webkit-transition: $transitioneffects !important;
    -moz-transition: $transitioneffects !important;
    -o-transition: $transitioneffects !important;
    transition: $transitioneffects !important;
}

@mixin transform($transformeffects) {
    -webkit-transform: $transformeffects !important;
    -moz-transform: $transformeffects !important;
    -o-transform: $transformeffects !important;
    transform: $transformeffects !important;
}

@mixin box-shadow($boxshadoweffects) {
    -webkit-box-shadow: $boxshadoweffects !important;
    -moz-box-shadow: $boxshadoweffects !important;
    -o-box-shadow: $boxshadoweffects !important;
    box-shadow: $boxshadoweffects !important;
}
